import { useBalancesQuery } from "@/redux/balance/balance.api";
import { useInfoQuery } from "@/redux/info/info.api";
import {
  useUserProfileQuery,
  useUserWalletsQuery,
} from "@/redux/user/user.api";
import { useAccount } from "wagmi";

export function useDashboard() {
  const { chainId, address = "" } = useAccount();

  const {
    data: info,
    isLoading: isInfoLoading,
    isSuccess: isInfoFetched,
    refetch: refetchInfo,
  } = useInfoQuery(chainId, {
    skip: !chainId,
  });

  const {
    data: profile,
    isLoading: isUserProfileLoading,
    isSuccess: isUserProfileFetched,
    refetch: refetchProfile,
  } = useUserProfileQuery(undefined, {
    skip: !chainId,
  });

  const {
    data: wallet,
    isLoading: isUserWalletsLoading,
    isSuccess: isUserWalletsFetched,
    refetch: refetchWallet,
  } = useUserWalletsQuery(
    {
      walletName: profile?.walletName || {},
      address,
      chainId,
      linkedAccounts: profile?.linkedAccounts,
    },
    { skip: !isUserProfileFetched },
  );

  const {
    data: balances,
    isLoading: isBalancesLoading,
    isSuccess: isBalancesFetched,
    isError: isBalancesError,
    isFetching: isBalancesFetching,
    refetch: refetchBalances,
  } = useBalancesQuery(
    {
      vaults: info?.vaults || [],
      joinnWalletAdddress: wallet?.joinnWalletAddress ?? "",
      chainId,
      userWallets: wallet?.joinnWallets || {},
    },
    { skip: !isUserWalletsFetched },
  );

  function initialize() {
    refetchInfo();
    refetchProfile();
    refetchWallet();
    refetchBalances();
  }

  return {
    info,
    userProfile: profile,
    userWallets: wallet,
    balances,
    isInfoLoading,
    isInfoFetched,
    isUserProfileLoading,
    isUserProfileFetched,
    isUserWalletsLoading,
    isUserWalletsFetched,
    isBalancesFetching,
    isBalancesLoading,
    isBalancesFetched,
    isBalancesError,
    refetch: initialize,
  };
}

import { Text } from "@/components";

type FrontCardProps = {
  balance: number;
  cardNumber: string;
  isHidden?: boolean;
};

export function FrontCard(props: Readonly<FrontCardProps>) {
  const { balance, cardNumber, isHidden } = props;
  return (
    <>
      <div>
        <Text variant="label" size="sm">
          Balance
        </Text>
        <Text variant="default" className="font-medium">
          {balance?.toFixed(2)} USDC
        </Text>
      </div>
      {!isHidden && (
        <Text variant="default" className="font-medium">
          **** {cardNumber.slice(-4)}
        </Text>
      )}
    </>
  );
}

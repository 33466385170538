import { Hex } from "@alchemy/aa-core";
// TODO: ADD SENTRY LATER
// import * as Sentry from "@sentry/react";
import { BOF_ACTIONS_ABI } from "@/abis";
import {
  ZeroDevAddresses,
  networks,
  paymaster,
  zerodevAddressByChain,
  zerodevProjects,
} from "@/api";
import { config } from "@/config";
import { BOF_ACTION, TOKEN_ADDRESSES } from "@/const";
import { signerToEcdsaValidator } from "@zerodev/ecdsa-validator";
import {
  KernelAccountClient,
  createKernelAccount,
  createKernelAccountClient,
} from "@zerodev/sdk";
import {
  KernelSmartAccount,
  verifyEIP6492Signature,
} from "@zerodev/sdk/accounts";
import {
  deserializeSessionKeyAccount,
  serializeSessionKeyAccount,
  signerToSessionKeyValidator,
} from "@zerodev/session-key";
import { Alchemy, Network } from "alchemy-sdk";
import dayjs from "dayjs";
import { BigNumber, Wallet, ethers } from "ethers";
import {
  Interface,
  defaultAbiCoder,
  hexConcat,
  hexlify,
} from "ethers/lib/utils.js";
import { ENTRYPOINT_ADDRESS_V06, UserOperation } from "permissionless";
import { SmartAccountSigner } from "permissionless/accounts";
import { ENTRYPOINT_ADDRESS_V06_TYPE, EntryPoint } from "permissionless/types";
import {
  ChainFees,
  ChainSerializers,
  HttpTransport,
  PublicClient,
  SignableMessage,
  Transport,
  zeroAddress as ZERO_ADDRESS,
  createPublicClient,
  hashMessage,
  http,
  toBytes,
} from "viem";
import { privateKeyToAccount } from "viem/accounts";
import { polygonAmoy } from "viem/chains";
import { Info } from "./interfaces";

export type AATransaction = {
  to: `0x${string}`;
  value: bigint;
  data: `0x${string}`;
};

const chainIdToNetwork: { [key: number]: Network } = {
  137: Network.MATIC_MAINNET,
  // 80001: Network.MATIC_MUMBAI,
  80002: Network.MATIC_AMOY,
  11155111: Network.ETH_SEPOLIA,
};

type KernelClientType = KernelAccountClient<
  EntryPoint,
  Transport,
  {
    blockExplorers: {
      readonly default: {
        readonly name: string;
        readonly url: string;
        readonly apiUrl: string;
      };
    };
    contracts: {
      readonly multicall3: {
        readonly address: Hex;
        readonly blockCreated: number;
      };
    };
    id: number;
    name: string;
    nativeCurrency: {
      readonly name: string;
      readonly symbol: string;
      readonly decimals: number;
    };
    rpcUrls: {
      readonly default: {
        readonly http: readonly string[];
      };
    };
    sourceId?: number | undefined;
    testnet: true;
    custom?: Record<string, unknown> | undefined;
    formatters?: undefined;
    serializers?: ChainSerializers<undefined> | undefined;
    fees?: ChainFees<undefined> | undefined;
  },
  KernelSmartAccount<EntryPoint, HttpTransport, undefined>
>;

type SessionObject = {
  session: string;
  validUntil: number;
  pk: Hex;
};

/**
 * Class representing an account abstraction.
 */
export class AccountAbstraction {
  // Entrypoint is this same for all chains
  private readonly ENTRYPOINT_ADDRESS = ENTRYPOINT_ADDRESS_V06; // ? We should upgreade to v7? https://docs.zerodev.app/sdk/advanced/session-keys
  private readonly DUMMY_SIGNATURE =
    "0xfffffffffffffffffffffffffffffff0000000000000000000000000000000007aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa1c";
  private PAYMASTER?: string;
  private ownerAccount?: ethers.providers.JsonRpcSigner;
  private alchemy?: Alchemy;
  private accountAddress?: `0x${string}`;
  private provider?: ethers.providers.BaseProvider;
  private immersveContractAddress?: string;
  private vaultContractAddresses?: string[];
  private usdcTokenAddress?: string;
  private BUNDLER_RPC?: string;
  private index = 0n;
  private chainId?: number;

  private publicClient?: PublicClient;

  private EXECUTOR_ADDRESS?: Hex;
  private kernelClient?: KernelClientType;
  private zerodevConfig?: ZeroDevAddresses;

  // No changes needed here, constructor removed.

  /**
   * Initializes an instance of the AccountAbstraction class.
   * @param info - The Info object containing information about the account.
   * @param signer - The JsonRpcSigner object used to sign transactions.
   * @param provider - The BaseProvider object used to interact with the Ethereum network.
   * @returns A Promise that resolves to an instance of the AccountAbstraction class.
   */
  static async init(
    info: Info,
    signer: ethers.providers.JsonRpcSigner,
    provider: ethers.providers.JsonRpcProvider,
    index = 0n,
  ): Promise<AccountAbstraction> {
    const self = new AccountAbstraction();
    await self.setup(info, signer, provider, index);
    return self;
  }

  /**
   * Converts a JsonRpcSigner to a SmartAccountSigner.
   * @param signer The JsonRpcSigner to convert.
   * @returns The converted SmartAccountSigner.
   */
  private async convertJsonRPCSignerToAccountSigner(
    signer: ethers.providers.JsonRpcSigner,
  ): Promise<SmartAccountSigner<"custom", Hex>> {
    const address = (await signer.getAddress()) as Hex;
    return {
      address,
      type: "local",
      publicKey: address, // Add the appropriate value for publicKey
      source: "custom", // Add the appropriate value for source
      signMessage: async ({ message }: { message: SignableMessage }) => {
        console.log("Call signMessage", message);
        const messageToSign =
          typeof message === "string" ? message : message.raw;
        return (await signer.signMessage(toBytes(messageToSign as Hex))) as Hex;
      },
      signTypedData: async (typedData) => {
        console.log("Call signTypedData", typedData);
        return (await signer._signTypedData(
          typedData.domain ?? {},
          // @ts-expect-error: these params should line up due to the spec for this function
          { [typedData.primaryType]: typedData.types[typedData.primaryType] },
          typedData.message,
        )) as Hex;
      },
    };
  }

  /**
   * Sets up the AccountAbstraction instance with the necessary information and providers.
   * @param info - An object containing information about the contracts and addresses.
   * @param signer - The JsonRpcSigner instance for the owner account.
   * @param provider - The BaseProvider instance for the network.
   * @returns A Promise that resolves when the setup is complete.
   * @throws An error if the ECDSA provider is already initialized.
   */
  public async setup(
    info: Info,
    signer: ethers.providers.JsonRpcSigner,
    provider: ethers.providers.BaseProvider,
    index = 0n,
  ) {
    this.index = index;
    this.provider = provider;
    this.PAYMASTER = info.paymasterAddress;
    this.ownerAccount = signer;
    this.immersveContractAddress = info.immersveAddress;
    this.vaultContractAddresses = info.vaults.map((v) => v.address);
    this.usdcTokenAddress = TOKEN_ADDRESSES[info.chainId].USDC.address;
    this.EXECUTOR_ADDRESS = info.kernelExecutor ?? undefined;

    this.chainId = this.provider.network.chainId;
    this.zerodevConfig = zerodevAddressByChain[this.chainId];
    this.BUNDLER_RPC = `https://rpc.zerodev.app/api/v2/bundler/${
      zerodevProjects[this.chainId]
    }`;
    this.alchemy = new Alchemy({
      apiKey: config.ALCHEMY_API_KEY,
      network: chainIdToNetwork[this.chainId],
    });
    this.publicClient = createPublicClient({
      transport: http(networks[this.chainId].rpcUrls[0]),
    });
  }

  private async getSponsorUserOperationMiddleware({
    userOperation,
  }: {
    userOperation: UserOperation<"v0.6">;
  }) {
    if (this.PAYMASTER && this.ownerAccount) {
      const estimate = await this.estimateUserOperationGas(userOperation);
      // Sentry.addBreadcrumb({
      //   data: estimate,
      //   message: "Estimated user operation gas",
      // });
      userOperation.paymasterAndData = "0x";
      userOperation.callGasLimit = BigInt(estimate.callGasLimit);
      userOperation.preVerificationGas = BigInt(estimate.preVerificationGas);
      userOperation.verificationGasLimit = BigInt(
        estimate.verificationGasLimit,
      );
      const validUntil = hexlify(dayjs().add(5, "minutes").unix());
      const validAfter = hexlify(dayjs().subtract(1, "minute").unix());
      const dummyPaymasterAndData = hexConcat([
        this.PAYMASTER,
        defaultAbiCoder.encode(["uint48", "uint48"], [validUntil, validAfter]),
        this.DUMMY_SIGNATURE,
      ]);
      const { paymasterAndData } = await paymaster
        .getPaymasterAndData(
          {
            callData: userOperation.callData,
            callGasLimit: hexlify(userOperation.callGasLimit),
            initCode: hexlify(userOperation.initCode as string),
            maxFeePerGas: hexlify(userOperation.maxFeePerGas),
            maxPriorityFeePerGas: hexlify(userOperation.maxPriorityFeePerGas),
            nonce: hexlify(userOperation.nonce),
            paymasterAndData: dummyPaymasterAndData,
            preVerificationGas: hexlify(userOperation.preVerificationGas),
            sender: userOperation.sender,
            signature: userOperation.signature,
            verificationGasLimit: hexlify(userOperation.verificationGasLimit),
          },
          validUntil,
          validAfter,
          await this.ownerAccount.getChainId(),
          this.zerodevConfig!.ENTRYPOINT_ADDRESS,
        )
        .catch((error) => {
          console.log("ERROR getPaymasterAndData", error);
          // Sentry.captureException(error);
          return { paymasterAndData: "0x" };
        });
      userOperation.paymasterAndData = paymasterAndData as Hex;
    }
    return userOperation;
  }

  private async getECDSAAccount() {
    if (!this.ownerAccount) throw new Error("Owner account not found");
    if (!this.EXECUTOR_ADDRESS) throw new Error("Executor address not found");
    if (!this.publicClient) throw new Error("Public client not initialized");
    if (!this.zerodevConfig) throw new Error("ZeroDev config not found");

    const ecdsaValidator = await signerToEcdsaValidator(this.publicClient, {
      signer: await this.convertJsonRPCSignerToAccountSigner(this.ownerAccount),
      entryPoint: this.zerodevConfig
        .ENTRYPOINT_ADDRESS as ENTRYPOINT_ADDRESS_V06_TYPE,
      kernelVersion: "0.2.4",
      validatorAddress: this.zerodevConfig.ECDSA_VALIDATOR,
    });

    const account = await createKernelAccount(this.publicClient, {
      plugins: {
        sudo: ecdsaValidator,
        action: {
          address: this.EXECUTOR_ADDRESS,
          selector: config.EXECUTOR_SELECTOR,
        },
      },
      kernelVersion: "0.2.4",
      index: this.index,
      entryPoint: this.zerodevConfig
        .ENTRYPOINT_ADDRESS as ENTRYPOINT_ADDRESS_V06_TYPE,
      accountImplementationAddress: this.zerodevConfig.ACCOUNT_LOGIC,
      factoryAddress: this.zerodevConfig.FACTORY_ADDRESS,
    });

    return createKernelAccountClient({
      account,
      chain: polygonAmoy,
      entryPoint: this.zerodevConfig
        .ENTRYPOINT_ADDRESS as ENTRYPOINT_ADDRESS_V06_TYPE,
      bundlerTransport: http(this.BUNDLER_RPC),
      middleware: {
        sponsorUserOperation: this.getSponsorUserOperationMiddleware.bind(this),
      },
    });
  }

  private async getSessionAccount() {
    if (!this.ownerAccount) throw new Error("Owner account not found");
    if (!this.EXECUTOR_ADDRESS) throw new Error("Executor address not found");
    if (!this.publicClient) throw new Error("Public client not initialized");
    if (!this.zerodevConfig) throw new Error("ZeroDev config not found");

    const sessionStorageKey = `session-${await this.ownerAccount.getAddress()}-${
      this.index
    }`;
    const sessionObj = sessionStorage.getItem(sessionStorageKey);
    let existedSession: SessionObject | null = sessionObj
      ? JSON.parse(sessionObj)
      : null;

    if (existedSession && existedSession.validUntil < dayjs().unix()) {
      sessionStorage.removeItem(sessionStorageKey);
      existedSession = null;
    }

    if (!existedSession) {
      // Create session
      const ecdsaValidator = await signerToEcdsaValidator(this.publicClient, {
        signer: await this.convertJsonRPCSignerToAccountSigner(
          this.ownerAccount,
        ),
        entryPoint: this.zerodevConfig
          .ENTRYPOINT_ADDRESS as ENTRYPOINT_ADDRESS_V06_TYPE,
        validatorAddress: this.zerodevConfig.ECDSA_VALIDATOR,
        kernelVersion: "0.2.4",
      });

      if (!this.EXECUTOR_ADDRESS) throw new Error("Executor address not found");

      // Session creation
      const sessionWallet = Wallet.createRandom();
      // Sentry.addBreadcrumb({
      //   data: { address: await sessionWallet.getAddress() },
      //   message: "Session wallet address",
      // });

      const sessionKeyValidator = await signerToSessionKeyValidator(
        this.publicClient,
        {
          kernelVersion: "0.2.4",
          entryPoint: this.zerodevConfig
            .ENTRYPOINT_ADDRESS as ENTRYPOINT_ADDRESS_V06_TYPE,
          validatorAddress: this.zerodevConfig.SESSION_KEY_VALIDATOR,
          signer: privateKeyToAccount(sessionWallet.privateKey as Hex),
          validatorData: {
            validAfter: dayjs().unix(),
            validUntil: dayjs().add(30, "minutes").unix(),
          },
        },
      );

      const account = await createKernelAccount(this.publicClient, {
        plugins: {
          regular: sessionKeyValidator,
          sudo: ecdsaValidator,
          address: this.zerodevConfig.SESSION_KEY_VALIDATOR,
          action: {
            address: this.EXECUTOR_ADDRESS,
            selector: config.EXECUTOR_SELECTOR,
          },
        },
        kernelVersion: "0.2.4",
        index: this.index,
        entryPoint: this.zerodevConfig
          .ENTRYPOINT_ADDRESS as ENTRYPOINT_ADDRESS_V06_TYPE,
        accountImplementationAddress: this.zerodevConfig.ACCOUNT_LOGIC,
        factoryAddress: this.zerodevConfig.FACTORY_ADDRESS,
      });

      const serializedSessionKey = await serializeSessionKeyAccount(account);
      existedSession = {
        session: serializedSessionKey,
        validUntil: dayjs().add(10, "minutes").unix(),
        pk: sessionWallet.privateKey as Hex,
      };
      sessionStorage.setItem(sessionStorageKey, JSON.stringify(existedSession));
    }

    if (!existedSession) throw new Error("Session not found");

    // Restore session
    const account = await deserializeSessionKeyAccount(
      this.publicClient,
      this.zerodevConfig.ENTRYPOINT_ADDRESS as ENTRYPOINT_ADDRESS_V06_TYPE,
      "0.2.4",
      existedSession.session,
      privateKeyToAccount(existedSession.pk),
      this.zerodevConfig.SESSION_KEY_VALIDATOR,
      this.zerodevConfig.FACTORY_ADDRESS,
      this.zerodevConfig.ACCOUNT_LOGIC,
    );

    return createKernelAccountClient({
      account,
      chain: polygonAmoy,
      entryPoint: this.zerodevConfig
        .ENTRYPOINT_ADDRESS as ENTRYPOINT_ADDRESS_V06_TYPE,
      bundlerTransport: http(this.BUNDLER_RPC),
      middleware: {
        sponsorUserOperation: this.getSponsorUserOperationMiddleware.bind(this),
      },
    });
  }

  /**
   * Get address of account abstraction
   * @returns {Promise<`0x${string}`>} address of account abstraction
   */
  async getAddress(): Promise<`0x${string}`> {
    const client = await this.getECDSAAccount();
    if (!this.accountAddress) {
      this.accountAddress = client.account?.address;
    }
    if (!this.accountAddress) throw new Error("Account address not found");
    return this.accountAddress;
  }

  /**
   * Waits for a user operation transaction to be confirmed on the blockchain.
   * @param opHash The hash of the user operation transaction to wait for.
   * @param confirmations The number of confirmations to wait for before resolving the promise. Default is 1.
   * @returns A promise that resolves when the transaction is confirmed.
   */
  async waitForUserOperationTransaction(opHash: string, confirmations = 1) {
    let txHash = null;

    class InternalError extends Error {
      constructor(message: string) {
        super(message);
        this.name = "InternalError";
      }
    }

    while (txHash === null) {
      try {
        let transactionHash = null;
        if (this.chainId && [7668, 7672].includes(this.chainId)) {
          // TRN network
          const response = await paymaster.txStatus(opHash, this.chainId);
          if (response.transactionHash) {
            transactionHash = response.transactionHash;
          }
          if (response.error) {
            throw new InternalError(response.error);
          }
        } else {
          const options = {
            method: "POST",
            headers: {
              accept: "application/json",
              "content-type": "application/json",
            },
            body: JSON.stringify({
              id: 1,
              jsonrpc: "2.0",
              method: "eth_getUserOperationByHash",
              params: [opHash],
            }),
          };

          const response = await fetch(
            `https://${this.alchemy?.config.network}.g.alchemy.com/v2/` +
              config.ALCHEMY_API_KEY,
            options,
          );
          const json = await response.json();

          transactionHash = json.result?.transactionHash || null;
        }

        if (transactionHash) {
          txHash = transactionHash;
          console.log(`AA transaction hash: ${transactionHash}`);
          // Sentry.addBreadcrumb({
          //   category: "transaction",
          //   message: `AA transaction hash: ${transactionHash}`,
          // });
        } else {
          const receipt = await this.provider?.getTransactionReceipt(opHash);
          if (receipt) txHash = receipt.transactionHash;
          await new Promise((innerResolve) => setTimeout(innerResolve, 5000));
        }
      } catch (err) {
        if (err instanceof InternalError) {
          throw err;
        }
        console.log("error and try to find txHash", err, opHash);
        txHash = opHash;
      }
    }
    return this.provider?.waitForTransaction(txHash, confirmations);
  }

  /**
   * Fetches the maximum priority fee per gas from the Alchemy API.
   * @returns A Promise that resolves to a string representing the maximum priority fee per gas.
   */
  async getMaxPriorityFeePerGas(): Promise<string> {
    const options = {
      method: "POST",
      headers: {
        accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        id: 1,
        jsonrpc: "2.0",
        method: "rundler_maxPriorityFeePerGas",
      }),
    };

    const response = await fetch(
      `https://${this.alchemy?.config.network}.g.alchemy.com/v2/${this.alchemy?.config.apiKey}`,
      options,
    );
    const json = await response.json();
    return json.result;
  }

  /**
   * @deprecated
   */
  async estimateUserOperationGasFromAlchemy(struct: any): Promise<{
    callGasLimit: number;
    preVerificationGas: number;
    verificationGasLimit: number;
  }> {
    if (!this.zerodevConfig) throw new Error("ZeroDev config not found");
    const options = {
      method: "POST",
      headers: {
        accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        id: 1,
        jsonrpc: "2.0",
        method: "eth_estimateUserOperationGas",
        params: [
          {
            ...struct,
            sender: await struct.sender,
            nonce: hexlify(struct.nonce),
            maxFeePerGas: hexlify(struct.maxFeePerGas),
            maxPriorityFeePerGas: hexlify(struct.maxPriorityFeePerGas),
            verificationGasLimit: hexlify(struct.verificationGasLimit),
            preVerificationGas: hexlify(struct.preVerificationGas),
            callGasLimit: hexlify(struct.callGasLimit),
          },
          this.zerodevConfig.ENTRYPOINT_ADDRESS, // Entry point address
        ],
      }),
    };

    const response = await fetch(
      `https://${this.alchemy?.config.network}.g.alchemy.com/v2/${this.alchemy?.config.apiKey}`,
      options,
    );

    const json = await response.json();
    if (json.error) {
      console.error(json);
      throw new Error(json.error.message);
    }
    return json.result;
  }

  async estimateUserOperationGas(struct: any): Promise<{
    callGasLimit: number;
    preVerificationGas: number;
    verificationGasLimit: number;
  }> {
    if (!this.provider) throw new Error("Provider not initialized");
    if (!this.zerodevConfig) throw new Error("ZeroDev config not found");

    // https://www.alchemy.com/blog/erc-4337-gas-estimation

    let callGasLimit = BigNumber.from("500000");
    let initGas = BigNumber.from("0");

    if (struct.initCode.length > 2) {
      const factoryInitCode = `0x${struct.initCode.slice(42)}`;
      initGas = await this.provider.estimateGas({
        from: this.zerodevConfig.ENTRYPOINT_ADDRESS,
        to: this.zerodevConfig.FACTORY_ADDRESS,
        data: factoryInitCode,
      });
    }
    callGasLimit = await this.provider.estimateGas({
      from: this.zerodevConfig.ENTRYPOINT_ADDRESS,
      to: struct.sender,
      data: struct.callData,
    });
    if (initGas.gt("21000")) {
      initGas = initGas.sub("21000");
    }
    if (callGasLimit.gt("21000")) {
      callGasLimit = callGasLimit.sub("21000");
    }
    // callGasLimit = callGasLimit.add("50000");

    const ownReturn = {
      callGasLimit: callGasLimit.add(initGas).toNumber(),
      preVerificationGas: 65_000,
      verificationGasLimit: callGasLimit
        .add(initGas)
        .add(struct.initCode.length > 2 ? 300_000 : 10_000)
        .toNumber(),
    };
    return ownReturn;
  }

  /**
   * Sends a batch of transactions using the Account Abstraction module.
   * @param transactions An array of UserOperationCallData objects representing the transactions to be sent.
   * @returns An object containing the hash of the transaction.
   * @throws An error if the ECDSA provider or provider is not initialized.
   */
  async sendTransactions(transactions: AATransaction | AATransaction[]) {
    if (!this.provider) throw new Error("Provider not initialized");
    if (!this.zerodevConfig) throw new Error("ZeroDev config not found");

    const provider = await this.getSessionAccount();

    if (!provider) throw new Error("Provider is not initialized");

    let tx;

    // Only for TRN network
    if (this.chainId && [7668, 7672].includes(this.chainId)) {
      const preparedTransaction = await provider.prepareUserOperationRequest({
        userOperation: {
          callData: await provider.account.encodeCallData(transactions),
        },
        // account: provider.account,
      });

      const signedTx =
        await provider.account.signUserOperation(preparedTransaction);

      tx = await paymaster.handleOp(
        {
          ...preparedTransaction,
          signature: signedTx,
          nonce: hexlify(preparedTransaction.nonce),
          callGasLimit: hexlify(preparedTransaction.callGasLimit),
          verificationGasLimit: hexlify(
            preparedTransaction.verificationGasLimit,
          ),
          preVerificationGas: hexlify(preparedTransaction.preVerificationGas),
          maxFeePerGas: hexlify(preparedTransaction.maxFeePerGas),
          maxPriorityFeePerGas: hexlify(
            preparedTransaction.maxPriorityFeePerGas,
          ),
          initCode: "0x", //TODO: this was changed to due to solve ts errors. Check
          paymasterAndData: "0x", //TODO: this was changed to due to solve ts errors. Check
        },
        this.chainId,
      );

      console.log("handleOps tx", tx);
      tx = tx.hash;
    } else {
      tx = await provider
        .sendUserOperation({
          userOperation: {
            callData: await provider.account.encodeCallData(transactions),
          },
          account: provider.account,
        })
        .catch((error: unknown) => {
          // Sentry.captureException(error);
          throw error;
        });
    }

    console.log("userOperationHash", tx);

    // Sentry.addBreadcrumb({
    //   category: "transaction",
    //   message: `User operation hash: ${tx}`,
    // });

    return { hash: tx };
  }

  /**
   * Checks if the contract is deployed.
   * @returns A promise that resolves to a boolean indicating whether the contract is deployed or not.
   * @throws An error if the provider is not initialized.
   */
  async contractIsDeployed(): Promise<boolean> {
    if (!this.provider) throw new Error("Provider not initialized");
    const code = await this.provider.getCode(await this.getAddress());
    return code !== "0x";
  }

  /**
   * Deploys the contract if it has not been deployed yet.
   * @returns {Promise<boolean>} A promise that resolves to true if the contract is deployed or has been successfully deployed, otherwise false.
   */
  async deployContractIfNotDeployed() {
    if (await this.contractIsDeployed()) return;
    const bofActionsInterface = new Interface(BOF_ACTIONS_ABI);
    const transactions = {
      to: await this.getAddress(),
      data: bofActionsInterface.encodeFunctionData("executeAction", [
        BOF_ACTION.EMPTY,
        ZERO_ADDRESS,
        ZERO_ADDRESS,
        ZERO_ADDRESS,
        "0x0",
        "0x0",
        "0x00",
      ]) as Hex,
      value: 0n,
    };
    const tx = await this.sendTransactions(transactions);

    if (!tx.hash) throw new Error("Transaction hash not found");
    await this.waitForUserOperationTransaction(tx.hash, 10);
    return true;
  }

  async signMessage(message: string) {
    const client = await this.getECDSAAccount();
    return client.account.signMessage({ message });
  }

  async verifySignature(message: string, signature: Hex): Promise<boolean> {
    if (!this.publicClient) throw new Error("Public client not initialized");
    return await verifyEIP6492Signature({
      client: this.publicClient,
      signer: await this.getAddress(),
      signature,
      hash: hashMessage(message),
    });
  }
}

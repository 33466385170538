import { Card } from "@/components/ui";
import { useRouteError } from "react-router-dom";
import JoinnLogo from "@/assets/images/joinnworkmark.svg";
import { Text } from "@/components";

export function ErrorElement() {
  const error = useRouteError() as { message: string };

  console.error(error);

  return (
    <div className="flex h-screen w-full items-center justify-center">
      <Card
        variant="container"
        size="md"
        className="w-1/3 items-center"
        radius="lg"
      >
        <img src={JoinnLogo} alt="logo" className="h-8" />
        <h1 className="text-xl">Ops! Something went wrong.</h1>
        <Text size="lg">Error occurred please try again later.</Text>
      </Card>
    </div>
  );
}

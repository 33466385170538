import PageFooter from "./Footer";
import PageNavigation from "./Navigation";

import ModalContainer from "@/pages/Modal/ModalContainer";
import { Outlet } from "react-router-dom";

export default function RootLayout() {
  return (
    <div className="min-h-screen bg-background p-5 max-md:bg-card max-md:p-3">
      <div className="m-auto max-w-screen-2xl">
        <PageNavigation />
        <Outlet />
        <ModalContainer />
        <PageFooter />
      </div>
    </div>
  );
}

import MastercardIcon from "@/assets/icons/mastercard.svg";
import MastercardBg from "@/assets/images/mastercardbg.svg";
import { Text } from "@/components";
import { Button, Card } from "@/components/ui";
import { cn } from "@/lib/utils";
import { useImmersveState } from "@/redux/immersve/immersve.slice";
import { useModalState } from "@/redux/modal/modal.slice";

export function MastercardActivation() {
  const { toggleModal } = useModalState();
  const { isConnecting } = useImmersveState();

  return (
    <Card
      radius="md"
      size="md"
      className={cn(
        "h-fit w-auto max-[360px]:border-none max-[360px]:p-0",
        isConnecting && "animate-pulse",
      )}
    >
      <div className="relative flex h-48 w-80 items-center justify-center rounded-2xl bg-primary max-[360px]:w-full">
        <img
          src={MastercardBg}
          alt="Mastercard Background"
          className="absolute"
        />
        {!isConnecting && (
          <>
            <img
              src={MastercardIcon}
              alt="Mastercard Icon"
              className="absolute right-4 top-4"
            />
            <div className="z-10 flex flex-col items-center gap-5 p-4 pt-10">
              <Text size="sm" className="text-center">
                Create a Mastercard to fully enjoy the services offered.
              </Text>
              <Button
                className="w-fit rounded-sm bg-accent px-10 py-2"
                onClick={() => {
                  toggleModal({
                    id: "pre-kyc-modal",
                    isFullWidth: true,
                  });
                }}
              >
                Continue Activation
              </Button>
            </div>
          </>
        )}
      </div>
    </Card>
  );
}

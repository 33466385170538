import { useImmersve, useSpending } from "@/hooks";
import { useLazyListFundingSourcesQuery } from "@/redux/immersve/immersve.api";
import { Outlet } from "react-router-dom";

export function Orders() {
  // const [initiateLogin] = useInitiateLoginMutation();
  const { state, action } = useImmersve();
  const { createFundingSource } = useSpending();

  const [
    triggerLazyListFundingSources,
    { data: fundingSources, error: sourcesError, isLoading: sourcessLoading },
  ] = useLazyListFundingSourcesQuery();

  return (
    <div className="py-5">
      <p>Orders Content here</p>
      <Outlet />
      <div className="flex items-center gap-5">
        <button onClick={action.login}>Login</button>
        <button onClick={action.logout}>Logout</button>

        <button
          onClick={(e) => {
            e.preventDefault();
            triggerLazyListFundingSources(state.tokenData.cardholderAccountId);
          }}
        >
          List Funding Sources
        </button>
        <button onClick={() => createFundingSource}>
          create funding source
        </button>
      </div>
    </div>
  );
}

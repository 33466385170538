import { Dropdown, Text } from "@/components";
import { SupportedRegions } from "@/components/kyc";
import { Button, Card } from "@/components/ui";
import { useImmersveState } from "@/redux/immersve/immersve.slice";
import { useKyc } from "@/redux/kyc/kyc.slice";
import { useUserProfileQuery } from "@/redux/user/user.api";
import { Routes } from "@/routes/routers";
import { MenuOption } from "@/types";
import { ArrowLeft } from "lucide-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ImmersveOnboarding } from "../ImmersveKYC/ImmersveOnboarding";
import OnboardingProcess from "./OnboardingProcess";

type Option = MenuOption & {
  route: string;
};

export function KnowYourCustomer() {
  const { disableOnboarding } = useKyc();
  const { data: user } = useUserProfileQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const { isConnected } = useImmersveState();

  const kycOptions = [
    {
      id: "fractal-verification",
      label: "User Onboarding and KYC Verification",
      route: Routes.KYC,
    },
    {
      id: "immersve-verification",
      label: "Mastercard Activation and KYC Verification",
      route: Routes.IMMERSVE_KYC,
      isDisabled: !isConnected,
    },
  ];

  const initialKycPage =
    location.pathname === Routes.KYC ? kycOptions[0].id : kycOptions[1].id;

  const [activeId, setActiveId] = useState<string>(initialKycPage);

  const isKycCompleted =
    user?.bofTermsOfService &&
    user?.bofPrivacyPolicy &&
    user?.swarmTermsOfService &&
    user?.swarmPrivacyPolicy &&
    user?.immersveTermsOfUse;

  useEffect(() => {
    if (!isKycCompleted) {
      disableOnboarding();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isKycCompleted]);

  return (
    <div className="my-5 flex flex-col gap-4">
      <div className="flex items-center gap-3">
        <Button
          className="rounded-full bg-black/25 p-3 max-md:p-3"
          onClick={() => {
            navigate(Routes.DASHBOARD);
          }}
        >
          <ArrowLeft color="hsl(var(--secondary))" />
        </Button>
        <Dropdown
          options={kycOptions}
          activeId={activeId}
          triggerProps={{
            arrowColor: "hsl(var(--secondary)/0.75)",
            className:
              "shrink hover:text-secondary/75 text-secondary text-lg sm:text-2xl",
          }}
          onSelectOption={(option) => {
            setActiveId(option?.id ?? "");
            navigate((option as Option).route);
          }}
        />
      </div>
      {activeId === "fractal-verification" && (
        <Card variant="container" size="md" radius="lg">
          {!isKycCompleted && (
            <SupportedRegions>
              <Text size="sm">
                Interacting with Joinn’s Earn Accounts and other services
                requires Users to successfully complete the Onboarding and KYC
                process. The Joinn platform is currently not available for use
                by US persons (as defined here) or persons that hold identities
                from other countries not serviced. US persons and users from
                non-serviced countries may connect a wallet to the Joinn
                platform and interact with features that do not require
                Onboarding and KYC approval but will not be able to interact
                with the above mentioned offerings.
              </Text>
              <Text size="sm">
                Joinn supports the onboarding and KYC of Users who are both a
                citizen and a resident of the following countries.
              </Text>
            </SupportedRegions>
          )}
          <OnboardingProcess />
        </Card>
      )}

      {activeId === "immersve-verification" && (
        <Card variant="container" size="md" radius="lg">
          <ImmersveOnboarding />
        </Card>
      )}
    </div>
  );
}

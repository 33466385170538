import { Link, Text } from "@/components";
import { Button, Card, Separator } from "@/components/ui";
import { HELP_IMMERSVE } from "@/const";
import { ImmersveStageStatus } from "@/const/kycStatus";
import { getKycBtnVariant, routeToUrl } from "@/lib/utils";
import { useModalState } from "@/redux/modal/modal.slice";
import { Prerequisite, UserKyc } from "@/services/interfaces";
import { KycProps } from "@/types";

type VerificationProps = KycProps & {
  user: UserKyc | null;
  kyc?: Prerequisite;
};

export function Verification(props: Readonly<VerificationProps>) {
  const { navButtons, kyc } = props;
  const { toggleModal } = useModalState();

  const isPending = kyc?.status === ImmersveStageStatus.PENDING;
  const isError = kyc?.status === ImmersveStageStatus.BLOCKED;
  const isSuccess = kyc?.status === ImmersveStageStatus.OK;

  function getBtnLabel() {
    let label = "Start KYC Verification";

    if (isPending) {
      label = "Processing";
    } else if (isError) {
      label = "Unable to verify identity, please try again.";
    } else if (isSuccess) {
      label = "Completed";
    }

    return label;
  }

  return (
    <div className="col-auto grid h-full max-w-screen-xl gap-6 lg:grid-cols-2">
      <div className="flex flex-col gap-5">
        {navButtons}
        <Text size="2xl" variant="secondary">
          Step 3: <span className="text-foreground">KYC Verification</span>
        </Text>
        <Text size="sm">
          The Mastercard KYC Verification process is simple and easy and should
          only take a few minutes to complete.
        </Text>
      </div>
      <Card
        variant={isSuccess ? "success" : "default"}
        className="flex flex-col justify-center gap-8"
      >
        <Button
          variant={getKycBtnVariant(kyc?.status ?? "")}
          disabled={isSuccess || isPending}
          size="md"
          className="w-2/4 rounded max-sm:w-full"
          onClick={() => {
            // Temporarily remove the modal while waiting for Immersve's decision about iframe
            // toggleModal({ id: "immersve-verification" });
            routeToUrl(kyc?.params?.kycUrl ?? "");
          }}
        >
          {getBtnLabel()}
        </Button>
        <Separator />
        {isError ? (
          <div>
            <Text size="sm" variant="label" className="font-bold">
              Your identity verification was unsuccessful.
            </Text>
            <Text size="sm" variant="label">
              For more information please contact the Immersve Support Team{" "}
              <Link
                to={HELP_IMMERSVE}
                className="font-semibold text-primary underline"
                target="_blank"
              >
                here.
              </Link>
            </Text>
          </div>
        ) : (
          <Text size="sm" variant="label">
            If assistance is required for Mastercard KYC Verification please
            contact the Immersve Support Team{" "}
            <Link
              to={HELP_IMMERSVE}
              className="font-semibold text-primary underline"
              target="_blank"
            >
              here.
            </Link>
          </Text>
        )}
      </Card>
    </div>
  );
}

import { customQueryFn } from "@/services/customQuery";
import {
  AccountAbstraction,
  AccountAbstractionRequest,
  ChangeNameReq,
  ChangeNameRes,
  JoinnWallet,
  UserProfile,
  UserProfileUpdateRequest,
} from "@/services/interfaces";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { api } from "../baseApi";

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    userWallets: builder.query<AccountAbstraction, AccountAbstractionRequest>({
      queryFn: async (request) => {
        const {
          walletName: { name, ensName },
          address,
          chainId,
          linkedAccounts = [],
        } = request;
        const mappedWallets: {
          [key: string]: JoinnWallet;
        } = {};

        const names: {
          [key: string]: string;
        } = {};

        try {
          if (!chainId || !address) {
            return {
              joinnWalletAddress: "",
              joinnWallets: mappedWallets,
              linkedAccounts: [],
              names,
            };
          }
          // TODO: Add check for !walletFactory

          const fetchProfileWallet = customQueryFn({
            url: `user/aa-address?address=${address}&chainId=${chainId}`,
          }).then(({ data }) => {
            return {
              address: data as string,
              name: name ?? ensName ?? "",
              owner: address,
              isCreated: false,
            };
          });

          const fetchLinkAccounts = linkedAccounts.map(async (a) => {
            return customQueryFn({
              url: `user/aa-address?address=${a.address}&chainId=${chainId}`,
            }).then(({ data }) => {
              return {
                address: data as string,
                name: a.walletName?.name ?? "",
                owner: a.address,
                isCreated: false,
              };
            });
          });

          const wallets = await Promise.all([
            fetchProfileWallet,
            ...fetchLinkAccounts,
          ]);

          mappedWallets[address.toLowerCase()] = wallets[0];
          names[address.toLowerCase()] = name ?? ensName ?? address;

          linkedAccounts.forEach((a, index) => {
            const addr = a.address.toLowerCase();
            mappedWallets[addr] = wallets[index + 1];
            names[addr] =
              a.walletName?.name ?? a.walletName?.ensName ?? a.address;
          });

          const accounts = linkedAccounts.map((a) => {
            const joinnWallet = mappedWallets[a.address.toLowerCase()]?.address;
            return { ...a, joinnWallet };
          });

          const data = {
            joinnWalletAddress: mappedWallets[address.toLowerCase()].address,
            joinnWallets: mappedWallets,
            linkedAccounts: accounts,
            names,
          };

          return { data };
        } catch (error) {
          return { error: error as FetchBaseQueryError };
        }
      },
    }),

    /**
     * Sequential API call to get User Information
     *
     */
    userProfile: builder.query<UserProfile, void>({
      query: () => ({
        url: "user/profile",
      }),
      providesTags: ["Profile"],
    }),

    updateUserProfile: builder.mutation<object, UserProfileUpdateRequest>({
      query: (props) => ({
        url: "user/profile",
        method: "PATCH",
        data: props,
      }),
      invalidatesTags: ["Profile"],
    }),

    changeName: builder.mutation<ChangeNameReq, ChangeNameRes>({
      query: ({ address, name }: ChangeNameReq) => ({
        url: `user/${address}/change-name`,
        method: "PUT",
        data: { name: name },
      }),
      invalidatesTags: ["Profile"],
    }),
  }),
});

export const {
  useUserProfileQuery,
  useUserWalletsQuery,
  useLazyUserProfileQuery,
  useLazyUserWalletsQuery,
  useUpdateUserProfileMutation,
  useChangeNameMutation,
} = userApi;

import { config } from "@/config";
import { useSpending } from "@/hooks";
import {
  useCreateCardMutation,
  useListCardsQuery,
} from "@/redux/immersve/immersve.api";
import { getImmersveTokenData, handleError } from "@/utils";

export function useMastercard() {
  const { cardholderAccountId } = getImmersveTokenData();
  const { hasFundingSource } = useSpending();

  const {
    data: listCardsData,
    refetch: listCardsRefetch,
    isFetching: listCardsIsFetching,
  } = useListCardsQuery(cardholderAccountId, {
    skip: !cardholderAccountId,
  });

  const [createCard, { isLoading: createCardIsLoading }] =
    useCreateCardMutation();

  async function createMastercard() {
    try {
      if (!hasFundingSource) throw new Error("No funding source found");
      await createCard({
        cardProgramId: config.IMMERSVE_CARD_PROGRAM_ID,
        fundingSourceId: hasFundingSource.id,
      }).unwrap();
      await listCardsRefetch();
    } catch (error) {
      console.error("Error creating mastercard", error);
      handleError(error);
    }
  }

  function getCardData() {
    if (!listCardsData) return;
    return listCardsData.items[0];
  }

  return {
    action: {
      createMastercard,
      listCardsRefetch,
    },
    state: {
      cardData: getCardData,
      hasMastercard: (listCardsData?.items?.length ?? 0) > 0,
      isCreatingProcessing: createCardIsLoading || listCardsIsFetching,
      isFetchingDetails: false,
    },
  };
}

import { Text } from "@/components";
import { useIcons } from "@/hooks";

type CardHeaderProps = {
  isFrontCard: boolean;
  date: string;
};

export function CardHeader({ isFrontCard, date }: Readonly<CardHeaderProps>) {
  const { JoinnIcon } = useIcons();

  return (
    <div>
      {isFrontCard ? (
        <>
          <Text variant="label" size="sm">
            Expiry
          </Text>
          <Text variant="default" className="font-medium">
            {date}
          </Text>
        </>
      ) : (
        <JoinnIcon className="w-12 opacity-100" />
      )}
    </div>
  );
}

import { Card, Separator } from "@/components/ui";
import { cn } from "@/lib/utils";
import React, { useState } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../ui";

type TransferDetailsProps = {
  caption?: string;
  captionOnHide?: string;
  data?: any[];
  className?: string;
};

export function TransferDetails({
  caption = "Show details",
  captionOnHide = "Hide details",
  data,
  className,
}: Readonly<TransferDetailsProps>) {
  const [isCollapsed, setIsCollapsed] = useState<string>("");
  if (!data) return null;
  return (
    <Accordion
      type="single"
      collapsible
      value={isCollapsed}
      onValueChange={setIsCollapsed}
      className={cn(className)}
    >
      <AccordionItem value="item-1">
        <AccordionContent>
          <Card className="p-2">
            {data.map((item, index) => (
              <React.Fragment key={item.item}>
                <div className="flex h-7 w-full items-center justify-between">
                  <div className="text-xs text-white/50">{item.item}</div>
                  <div className="text-right text-xs text-secondary">
                    {item.value} USDC
                  </div>
                </div>
                {index !== data.length - 1 && (
                  <Separator className="col-span-2" />
                )}
              </React.Fragment>
            ))}
          </Card>
        </AccordionContent>
        <AccordionTrigger
          noIcon
          variant="primary"
          size="md"
          className="flex w-full justify-end"
        >
          {isCollapsed === "item-1" ? captionOnHide : caption}
        </AccordionTrigger>
      </AccordionItem>
    </Accordion>
  );
}

import { cn } from "@/lib/utils";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

const buttonVariants = cva(
  "text-start max-sm:text-xs text-sm inline-flex items-center justify-center rounded-lg ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-25 gap-2",
  {
    variants: {
      variant: {
        default: "hover:opacity-75",
        primary:
          "bg-accent text-accent-foreground hover:bg-primary/75 border-solid border border-primary",
        secondary:
          "bg-accent-foreground text-accent hover:bg-accent-foreground/75 border-solid border border-primary",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline:
          "border border-primary hover:bg-black/50 hover:text-accent-foreground",
        ghost: "hover:text-accent",
        link: "text-primary underline-offset-4 hover:underline",
        success: "bg-success/75 border-success disabled:opacity-1",
        pending: "bg-yellow-500/75 border-yellow-500 disabled:opacity-1",
        error:
          "bg-destructive/75 text-destructive-foreground hover:bg-destructive",
        unset: "",
      },
      radius: {
        default: "rounded-sm",
        base: "rounded-base",
        md: "rounded-md",
        lg: "rounded-lg",
      },
      size: {
        default: "",
        sm: "py-2 px-3 max-sm:px-2",
        md: "py-2 px-6 max-sm:px-4",
        lg: "h-11 px-8",
        xl: "h-12  px-5",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
      radius: "default",
    },
  },
);

interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  readOnly?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      radius,
      asChild = false,
      readOnly = false,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(
          readOnly && "pointer-events-none",
          buttonVariants({ variant, size, radius, className }),
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants, type ButtonProps };

import { Text } from "@/components";
import { Button, Card, Separator } from "@/components/ui";
import {
  ACCEPTED_COUNTRIES,
  FRACTAL_EMAIL,
  FRACTAL_ID,
  FRACTAL_PRIVACY_POLICY,
  FRACTAL_SECURITY,
} from "@/const";
import { useModalState } from "@/redux/modal/modal.slice";
import { UserKyc } from "@/services/interfaces";
import { KycBtnVariants, KycProps } from "@/types/kyc";
import { Link } from "react-router-dom";

type VerificationProps = KycProps & {
  user: UserKyc | null;
};

export function KycVerification(props: VerificationProps) {
  const { navButtons, isStepCompleted, user } = props;
  const { toggleModal } = useModalState();

  const hasStartedKyc =
    user?.status === "IN_PROGRESS" ||
    user?.status === "AWAITING_DATA" ||
    user?.status === "IN_VERIFICATION";

  const shouldRestartKyc = hasStartedKyc && !user?.accountId;
  const isKycPending = hasStartedKyc && user?.accountId;
  const isKycError = user?.status === "ERROR";
  const isKycDeclined = user?.status === "DECLINED";

  function getBtnLabel() {
    let label = "Start KYC Verification";

    if (isStepCompleted) {
      label = "Completed";
    } else if (shouldRestartKyc) {
      label = "Restart KYC Verification";
    }

    return label;
  }

  function getCardVariant() {
    let variant = "default";

    if (isKycError || isKycDeclined) {
      variant = "error";
    } else if (isStepCompleted) {
      variant = "success";
    }

    return variant as KycBtnVariants;
  }

  return (
    <div className="col-auto grid max-w-screen-xl grid-cols-2 gap-6 max-md:grid-cols-1">
      <div className="flex flex-col gap-5">
        {navButtons}
        <Text size="2xl" variant="secondary">
          Step 3: <span className="text-foreground">KYC Verification</span>
        </Text>
        <Text size="sm">
          The KYC Verification process is simple and easy and should only take a
          few minutes to complete. Once successfully verified you will be able
          to proceed to the final step.
        </Text>
        <Text size="sm">
          For the safety of our Users, Joinn only stores Users email addresses.
          All other personal User information collected during the KYC process
          is stored by our third party KYC provider{" "}
          <Link
            to={FRACTAL_ID}
            className="font-semibold text-primary underline"
            target="_blank"
          >
            Fractal ID
          </Link>{" "}
          and in line with their{" "}
          <Link
            to={FRACTAL_SECURITY}
            className="font-semibold text-primary underline"
            target="_blank"
          >
            strict security measures{" "}
          </Link>{" "}
          and{" "}
          <Link
            to={FRACTAL_PRIVACY_POLICY}
            className="font-semibold text-primary underline"
            target="_blank"
          >
            privacy policy.
          </Link>
        </Text>
      </div>
      <Card
        className="flex flex-col items-start justify-center gap-8"
        variant={getCardVariant()}
      >
        {isKycPending && (
          <>
            <Button
              variant="pending"
              size="md"
              className="w-2/4 rounded max-md:w-full"
              disabled
            >
              Pending for Verification
            </Button>
            <Separator />
            <div className="flex flex-col gap-4">
              <Text size="sm" variant="label">
                <span className="font-semibold">
                  Your identity verification is in progress,
                </span>{" "}
                this typically takes Fractal ID approximately 10 minutes to
                verify your identity.
              </Text>
              <Text size="sm" variant="label">
                You will receive a confirmation email once your documents have
                been successfully submitted and a subsequent email either
                requesting more information or approving/denying your
                verification request.
              </Text>
              <Text size="sm" variant="label">
                If you experience any issues, have not received email
                confirmation of your submission, have not received verification
                approval/denial within 24hrs of submission or have any general
                questions, please contact the Fractal ID Support Team either via{" "}
                <Link
                  to={`mailto:${FRACTAL_EMAIL}`}
                  className="font-semibold text-primary underline"
                  target="_blank"
                >
                  email
                </Link>{" "}
                or their{" "}
                <Link
                  to={`mailto:${FRACTAL_EMAIL}`}
                  className="font-semibold text-primary underline"
                  target="_blank"
                >
                  Telegram Support Bot.
                </Link>
              </Text>
            </div>
          </>
        )}

        {isKycError && (
          <>
            <Button
              variant="error"
              size="md"
              className="w-2/4 rounded max-md:w-full"
            >
              Unable to Verify Identity
            </Button>
            <Separator />
            <div className="flex flex-col gap-4">
              <Text size="sm" variant="label">
                <span className="font-semibold">
                  Your identity verification was unsuccessful.
                </span>
              </Text>
              <Text size="sm" variant="label">
                Please contact the Fractal ID Support Team either via{" "}
                <Link
                  to={`mailto:${FRACTAL_EMAIL}`}
                  className="font-semibold text-primary underline"
                  target="_blank"
                >
                  email
                </Link>{" "}
                or their{" "}
                <Link
                  to={`mailto:${FRACTAL_EMAIL}`}
                  className="font-semibold text-primary underline"
                  target="_blank"
                >
                  Telegram Support Bot
                </Link>{" "}
                if you have any questions.
              </Text>
            </div>
          </>
        )}

        {isKycDeclined && (
          <>
            <Button
              variant="error"
              size="md"
              className="disabled:opacity-1 w-3/4 rounded max-md:w-full"
              disabled
            >
              Citizenship Not Currently Supported
            </Button>
            <Separator />
            <div className="flex flex-col gap-4">
              <Text size="sm" variant="label">
                <span className="font-semibold">
                  Sorry Joinn does not support the onboarding of users from your
                  country of citizenship at this time.
                </span>
                Joinn is currently only available to citizens of the following{" "}
                <Link
                  to={ACCEPTED_COUNTRIES}
                  className="font-semibold text-primary underline"
                  target="_blank"
                >
                  countries.
                </Link>
              </Text>
              <Text size="sm" variant="label">
                Please contact the Fractal ID Support Team either via{" "}
                <Link
                  to={`mailto:${FRACTAL_EMAIL}`}
                  className="font-semibold text-primary underline"
                  target="_blank"
                >
                  email
                </Link>{" "}
                or their{" "}
                <Link
                  to={`mailto:${FRACTAL_EMAIL}`}
                  className="font-semibold text-primary underline"
                  target="_blank"
                >
                  Telegram Support Bot
                </Link>{" "}
                if you have any questions.
              </Text>
            </div>
          </>
        )}

        {(shouldRestartKyc || isStepCompleted || !user) && (
          <>
            <Button
              variant={isStepCompleted ? "success" : "primary"}
              disabled={isStepCompleted}
              size="md"
              className="w-2/4 rounded"
              onClick={() => {
                toggleModal({ id: "fractal-verification" });
              }}
            >
              {getBtnLabel()}
            </Button>
            <Separator />
            <Text size="sm" variant="label">
              If you have any problems please contact{" "}
              <Link
                to={`mailto:${FRACTAL_EMAIL}`}
                className="font-semibold text-primary underline"
                target="_blank"
              >
                support@Fractal.id
              </Link>
            </Text>
          </>
        )}
      </Card>
    </div>
  );
}

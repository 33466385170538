import * as React from "react";

import { cn } from "@/lib/utils";
import { cva, VariantProps } from "class-variance-authority";

const cardVariants = cva(
  "border border-solid shadow-md shadow-black/50 w-full max-md:[clip-path:none]",
  {
    variants: {
      variant: {
        default: "border-border/50 bg-background/50",
        secondary: "border-secondary/25 bg-black/15",
        pending: "border-yellow-500 bg-yellow-500/5",
        success: "border-success/50 bg-green-500/5",
        error: "border-destructive bg-red-500/5",
        container:
          "border-none md:bg-card max-md:shadow-none max-md:p-0 grid gap-5",
      },
      radius: {
        default: "rounded",
        base: "rounded-base",
        md: "rounded-md",
        lg: "rounded-lg",
      },
      size: {
        default: "p-8 max-sm:p-4",
        sm: "p-3",
        md: "p-5",
        lg: "p-10",
      },
    },

    defaultVariants: {
      variant: "default",
      size: "default",
      radius: "default",
    },
  },
);

interface CardProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof cardVariants> {}

const Card = React.forwardRef<HTMLDivElement, CardProps>(
  ({ className, variant, size, radius, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(cardVariants({ variant, size, radius, className }))}
      {...props}
    />
  ),
);
Card.displayName = "Card";

const CardHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("flex flex-col space-y-1.5 p-6", className)}
    {...props}
  />
));
CardHeader.displayName = "CardHeader";

const CardTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h3
    aria-hidden="true"
    ref={ref}
    className={cn(
      "text-2xl font-semibold leading-none tracking-tight",
      className,
    )}
    {...props}
  />
));
CardTitle.displayName = "CardTitle";

const CardDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn("text-sm text-muted-foreground", className)}
    {...props}
  />
));
CardDescription.displayName = "CardDescription";

const CardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn("p-6 pt-0", className)} {...props} />
));
CardContent.displayName = "CardContent";

const CardFooter = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("flex items-center p-6 pt-0", className)}
    {...props}
  />
));
CardFooter.displayName = "CardFooter";

export {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
  type CardProps,
};

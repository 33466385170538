export enum Accounts {
  ALL = "All",
  SPENDING_ACCOUNT = "Spending Account",
  AUTOMATED_LENDING = "Automated Lending",
  BDC_INCOME = "BDC Income",
  US_TREASURY_BONDS = "US Treasury Bonds",
}

export const AccountsAddressByChain: {
  [key: string]: { id: string; label: string; address: string }[];
} = {
  80001: [
    {
      id: "automated-lending",
      label: "Automated Lending",
      address: "0x4d7F05D2c25bEcFC93fB3445e924212466d99393",
    },
    {
      id: "bdc-income",
      label: "BDC Income",
      address: "0x295FF1FfC397500a596C84656af9fe9790cE7dEb",
    },
    {
      id: "us-treasury-bonds",
      label: "US Treasury Bonds",
      address: "0x61f732101395d8D8C11DB5F8d6a7e9E7C9dF182B",
    },
    // TODO: Enable this as soon as Spending Account is completed
    // {
    //   id: "spending-account",
    //   label: "Spending Account",
    //   address: "0x131adAd8BDF54C52E13e3Fd9e501245c0313B75b",
    // },
  ],
  80002: [
    {
      id: "automated-lending",
      label: "Automated Lending",
      address: "0x4d7F05D2c25bEcFC93fB3445e924212466d99393",
    },
    {
      id: "bdc-income",
      label: "BDC Income",
      address: "0x295FF1FfC397500a596C84656af9fe9790cE7dEb",
    },
    {
      id: "us-treasury-bonds",
      label: "US Treasury Bonds",
      address: "0x61f732101395d8D8C11DB5F8d6a7e9E7C9dF182B",
    },
    {
      id: "spending-account",
      label: "Spending Account",
      address: "0x131adAd8BDF54C52E13e3Fd9e501245c0313B75b",
    },
  ],
  7672: [
    {
      id: "automated-lending",
      label: "US Treasury Bonds",
      address: "0xe2D060BCEF1541fbF160299faDEE55049802B0c4",
    },
  ],
  // TODO: To be changed when support is enabled
  7668: [
    {
      id: "automated-lending",
      label: "Automated Lending",
      address: "0x4d7F05D2c25bEcFC93fB3445e924212466d99393",
    },
    {
      id: "bdc-income",
      label: "BDC Income",
      address: "0x295FF1FfC397500a596C84656af9fe9790cE7dEb",
    },
    {
      id: "us-treasury-bonds",
      label: "US Treasury Bonds",
      address: "0x61f732101395d8D8C11DB5F8d6a7e9E7C9dF182B",
    },
  ],
  11155111: [
    {
      id: "us-treasury-bonds",
      label: "US Treasury Bonds",
      address: "0xE23D03AdaFE976222E9b0c6cc214df1e745B1D25",
    },
  ],
};

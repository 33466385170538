import { Button } from "@/components/ui";
import { config } from "@/config";
import { Transactions } from "@/const";
import { useSpending, useTransactionNav } from "@/hooks";

export function ButtonsSet() {
  const { hasFundingSource } = useSpending();
  const { handleTransactionNav } = useTransactionNav();

  return (
    <>
      {hasFundingSource && (
        <div className="flex grow items-center gap-2">
          <Button
            variant="secondary"
            size="sm"
            onClick={() =>
              handleTransactionNav(
                Transactions.DEPOSIT,
                config.IMMERSVE_FUNDING_STORAGE_ADDRESS,
              )
            }
          >
            Deposit
          </Button>
          <Button
            size="sm"
            variant="primary"
            onClick={() =>
              handleTransactionNav(
                Transactions.WITHDRAW,
                config.IMMERSVE_FUNDING_STORAGE_ADDRESS,
              )
            }
          >
            Withdraw
          </Button>
          <Button
            size="sm"
            variant="primary"
            onClick={() =>
              handleTransactionNav(
                Transactions.TRANSFER,
                config.IMMERSVE_FUNDING_STORAGE_ADDRESS,
              )
            }
          >
            Transfer
          </Button>
        </div>
      )}
    </>
  );
}

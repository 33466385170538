import { AutoComplete, InputField, Option, Text } from "@/components";
import { Card, Separator } from "@/components/ui";
import { convertToCurrency } from "@/lib/utils";
import { useGetImmersveCurrencyQuery } from "@/redux/accountInfo/currency";
import { useCallback, useState } from "react";

export function CurrencyCalculator() {
  const { data: currencies, isSuccess } = useGetImmersveCurrencyQuery();

  const getCurrencyOptions = useCallback(() => {
    return (
      currencies?.map((currency) => {
        return {
          ...currency,
          label: currency.symbol,
          name: currency.label,
        };
      }) ?? []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const [selectedCurrency, setSelectedCurrency] = useState<Option>(
    getCurrencyOptions()[0],
  );
  const [inputValue, setInputValue] = useState<string>("");

  function formatInputValue(value: string) {
    const formattedValue = value.replaceAll(",", "");
    if (/^\d*\.?\d{0,5}$/.test(formattedValue)) {
      setInputValue(formattedValue);
    }
  }

  function getAmountRequired() {
    return (Number(inputValue) / Number(selectedCurrency?.usdRate)).toFixed(4);
  }

  return (
    <Card className="rounded-lg p-0 max-sm:p-0" id="currency-calculator">
      <Text size="lg" variant="primary" className="px-8 py-6 max-md:p-3">
        Currency Calculator
      </Text>
      <Separator />
      <div className="grid gap-5 px-8 py-6 max-md:p-3">
        <Text size="sm">
          You can use this Currency Calculator to view the current approximate
          real-time USDC value required to make a purchase inclusive of any
          fees.
        </Text>
        <div>
          <InputField
            label="Purchase Value"
            placeholder="0"
            value={convertToCurrency(inputValue)}
            onChange={(event) => {
              const value = event.target.value;
              formatInputValue(value);
            }}
            endAdornment={
              <AutoComplete
                placeHolder="Currency"
                value={selectedCurrency}
                options={getCurrencyOptions()}
                onSelect={setSelectedCurrency}
                triggerProps={{
                  className: "border-none bg-secondary/5 w-fit  pr-2 text-sm",
                }}
              />
            }
          />
          <Text size="sm" className="w-full p-2 text-end">
            1 USDC ={" "}
            <span className="text-secondary">
              {selectedCurrency ? (
                <>
                  {selectedCurrency?.usdRate} {selectedCurrency?.symbol}
                </>
              ) : (
                0.0
              )}
            </span>
          </Text>
        </div>
        <div>
          <Text size="sm" className="w-full">
            Amount Required:{" "}
            <span className="text-secondary">{`${getAmountRequired()} USDC`}</span>
          </Text>
          <Text size="sm" className="w-full pt-1">
            Spending Account Balance:{" "}
            <span className="text-secondary">0.00 USDC</span>
          </Text>
        </div>
      </div>
    </Card>
  );
}

import JoinnLogo from "@/assets/images/logo.svg";
import { Box, BoxContent, Text } from "@/components";
import {
  Separator,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui";
import { PRIVACY_POLICY, TWITTER_JOINN } from "@/const";
import { NavLink } from "react-router-dom";

export default function PageFooter() {
  return (
    <TooltipProvider delayDuration={100}>
      <Box radius="lg">
        <BoxContent className="flex flex-wrap gap-5 max-[835px]:justify-center min-[835px]:justify-between">
          <div className="flex flex-wrap items-center justify-center gap-2 text-center">
            <div className="flex items-center justify-center max-[815px]:[flex:1_0_100%]">
              <img src={JoinnLogo} className="max-w-12" alt="Footer Logo" />
            </div>
            <Text>Copyright © 2024 Joinn. All rights reserved.</Text>
          </div>
          <Separator orientation="horizontal" className="min-[450px]:hidden" />
          <div className="flex flex-wrap items-center justify-center gap-2 text-center">
            <div className="flex items-center justify-center max-[450px]:[flex:1_0_100%]">
              <Tooltip>
                <TooltipTrigger>
                  {/* TODO: Make this a Navlink and remove tooltip when the URL is available */}
                  <Text variant="menu">Discord</Text>
                </TooltipTrigger>
                <TooltipContent>
                  <Text>Coming soon!</Text>
                </TooltipContent>
              </Tooltip>
            </div>
            <Separator orientation="vertical" className="max-[450px]:hidden" />
            <div className="flex items-center justify-center max-[450px]:[flex:1_0_100%]">
              <NavLink to={TWITTER_JOINN} target="_blank">
                <Text variant="menu">X</Text>
              </NavLink>
            </div>
            <Separator orientation="vertical" className="max-[450px]:hidden" />
            <div className="flex items-center justify-center max-[450px]:[flex:1_0_100%]">
              <Tooltip>
                <TooltipTrigger>
                  {/* TODO: Make this a Navlink and remove tooltip when the URL is available */}
                  <Text variant="menu">Blog</Text>
                </TooltipTrigger>
                <TooltipContent>
                  <Text>Coming soon!</Text>
                </TooltipContent>
              </Tooltip>
            </div>
            <Separator orientation="vertical" className="max-[450px]:hidden" />
            <div className="flex items-center justify-center max-[450px]:[flex:1_0_100%]">
              <Tooltip>
                <TooltipTrigger>
                  {/* TODO: Make this a Navlink and remove tooltip when the URL is available */}
                  <Text variant="menu">Terms of Service</Text>
                </TooltipTrigger>
                <TooltipContent>
                  <Text>Coming soon!</Text>
                </TooltipContent>
              </Tooltip>
            </div>
            <Separator orientation="vertical" className="max-[450px]:hidden" />
            <div className="flex items-center justify-center max-[450px]:[flex:1_0_100%]">
              <NavLink to={PRIVACY_POLICY} target="_blank">
                <Text variant="menu">Privacy Policy</Text>
              </NavLink>
            </div>
          </div>
        </BoxContent>
      </Box>
    </TooltipProvider>
  );
}

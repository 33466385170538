import { Text } from "@/components";
import { Skeleton } from "@/components/ui";

type BackCardProps = {
  isLoading?: boolean;
  cardNumber: string;
  date?: string;
  cvc?: number;
};

export function BackCard(props: Readonly<BackCardProps>) {
  const { isLoading, cardNumber, date, cvc } = props;
  return (
    <div className="flex flex-col gap-1">
      <Text variant="default" className="font-medium">
        {isLoading ? (
          <div>
            <Skeleton className="w-38 h-5 bg-accent-foreground/80" />
          </div>
        ) : (
          cardNumber
        )}
      </Text>
      <div className="flex gap-4">
        <div className="flex items-center gap-2">
          <Text variant="label" size="xs">
            EXP
          </Text>
          {/* TODO: This is semantically incorrect - div within a text */}
          <Text variant="default" className="font-medium">
            {isLoading ? (
              <div>
                <Skeleton className="h-5 w-8 bg-accent-foreground/80" />
              </div>
            ) : (
              date
            )}
          </Text>
        </div>
        <div className="flex items-center gap-2">
          <Text variant="label" size="xs">
            CVC
          </Text>
          {/* TODO: This is semantically incorrect - div within a text */}
          <Text variant="default" className="font-medium">
            {isLoading ? (
              <div>
                <Skeleton className="h-5 w-8 bg-accent-foreground/80" />
              </div>
            ) : (
              cvc
            )}
          </Text>
        </div>
      </div>
    </div>
  );
}

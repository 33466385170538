import { useIcons } from "@/hooks";
import { Text } from "./typography";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "./ui";

type JoinnTooltipProps = {
  description: string;
  type?: "icon" | "text";
  children: React.ReactNode;
};

export function JoinnTooltip({
  description,
  type = "icon",
  children,
}: Readonly<JoinnTooltipProps>) {
  const { InfoIcon2 } = useIcons();

  return (
    <TooltipProvider delayDuration={100}>
      <Tooltip>
        {type === "icon" ? (
          <div className="flex items-center gap-2">
            {children}
            <TooltipTrigger>
              <InfoIcon2 />
            </TooltipTrigger>
          </div>
        ) : (
          <TooltipTrigger>{children}</TooltipTrigger>
        )}
        <TooltipContent className="flex items-center gap-2">
          <Text>{description}</Text>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

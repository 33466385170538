import JoinnLogoBackground from "@/assets/mastercard/joinn-logo.svg";
import McLogo from "@/assets/mastercard/mc-logo.svg";
import NfcLogo from "@/assets/mastercard/nfc.svg";

import { Button, Card } from "@/components/ui";
import { useBalances, useIcons } from "@/hooks";
import { BackCard, CardHeader, FrontCard } from "@/pages/Dashboard/Mastercard";
import { useModalState } from "@/redux/modal/modal.slice";
import dayjs from "dayjs";
import { useState } from "react";

export default function Mastercard() {
  const { toggleModal } = useModalState();
  const { HideIcon, ShowIcon } = useIcons();

  const [isHovering, setIsHovering] = useState(false);
  const [isFrontCard, setIsFrontCard] = useState(false);
  const { spendingAccountBalance } = useBalances();

  const cardNumber = "4566 8654 4568 9909";
  const date = dayjs.unix(1728548290).format("MM/YY");
  const cvc = 123;

  return (
    <Card
      radius="md"
      size="md"
      className="w-auto max-[360px]:border-none max-[360px]:p-0"
    >
      <div
        className="relative flex h-48 w-80 items-center justify-center rounded-2xl bg-[hsl(203,89%,53%)] p-4 max-[360px]:w-full"
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        aria-hidden="true"
      >
        <img
          src={JoinnLogoBackground}
          alt="Joinn Logo"
          className="absolute z-0"
        />
        <img src={NfcLogo} alt="NFC Logo" className="absolute right-4 z-0" />
        <div className="z-10 flex h-full w-full flex-col justify-between">
          <div className="flex items-start justify-between">
            <CardHeader isFrontCard={isFrontCard} date={date} />
            <img src={McLogo} alt="Mastercard Logo" />
          </div>
          <div>
            <div className="flex items-end justify-between">
              {isFrontCard ? (
                <FrontCard
                  balance={spendingAccountBalance ?? 0}
                  cardNumber={cardNumber}
                  isHidden={isHovering}
                />
              ) : (
                <BackCard cardNumber={cardNumber} cvc={cvc} date={date} />
              )}
            </div>
            {isHovering && (
              <div className="gap mt-2 flex items-center gap-3">
                <Button
                  variant="primary"
                  className="w-full py-2"
                  onClick={() => {
                    setIsFrontCard(!isFrontCard);
                  }}
                >
                  {isFrontCard ? <HideIcon /> : <ShowIcon />}
                  {`${isFrontCard ? "Hide" : "Display"} details`}
                </Button>
                <Button
                  variant="primary"
                  className="px-4 py-2"
                  onClick={() =>
                    toggleModal({
                      title: "Cancel Mastercard",
                      id: "cancel-mc",
                    })
                  }
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
}

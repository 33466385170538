import { Stepper, Text } from "@/components";
import { SupportedRegions } from "@/components/kyc";
import { Button, Card } from "@/components/ui";
import { config } from "@/config";
import { COUNTRIES, Country, ImmersveStageStatus } from "@/const";
import { useSpending } from "@/hooks";
import { useGetSupportedRegionsQuery } from "@/redux/immersve/immersve.api";
import { useUserProfileQuery } from "@/redux/user/user.api";
import { useEffect, useMemo, useState } from "react";
import { Activation, Information, SuccessPage, Verification } from "./Steps";

export function ImmersveOnboarding() {
  const { data: user, isSuccess, isFetching } = useUserProfileQuery();
  const { kyc } = useSpending();

  const { data: regions, isSuccess: isRegionsFetched } =
    useGetSupportedRegionsQuery(config.IMMERSVE_PARTNER_ACCOUNT_ID);

  const [activeStep, setActiveStep] = useState(3);
  const [completedStep, setCompletedStep] = useState(2);

  const steps = ["Information", "Activation", "KYC Verification"];

  const supportedRegions = useMemo(() => {
    return (
      regions?.regions
        .filter((region) => {
          return region.isAvailable;
        })
        .map((region) => {
          return COUNTRIES.find((country) => {
            return country.label === region.title;
          });
        }) ?? [{ label: "New Zealand", currencyCode: "NZD" }]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRegionsFetched]);

  const isAcceptanceValid = useMemo(() => {
    if (user?.immersveTermsOfUse) {
      const date = new Date(user?.immersveTermsOfUse);
      // Temporary check
      const baseDate = new Date("11-01-24");
      return date > baseDate;
    } else return false;
  }, [user]);

  function setStep(step: number) {
    setCompletedStep(step);
    setActiveStep(step + 1);
  }

  function handleBack() {
    setActiveStep(activeStep - 1);
  }

  function handleNext() {
    setActiveStep(activeStep + 1);
  }

  function getCompletedStep() {
    if (kyc?.status === ImmersveStageStatus.OK) {
      setStep(3);
    } else if (isAcceptanceValid) {
      setStep(2);
    } else {
      setStep(0);
    }
  }

  function getNavButtons() {
    return (
      <div className="flex gap-3 pb-5 max-md:pb-3">
        <Button
          disabled={activeStep <= 1}
          variant="outline"
          size="md"
          onClick={() => {
            handleBack();
          }}
          className="rounded"
        >
          Back
        </Button>
        <Button
          disabled={activeStep - 1 >= completedStep}
          variant="primary"
          size="md"
          className="rounded"
          onClick={() => {
            handleNext();
          }}
        >
          Next
        </Button>
      </div>
    );
  }

  useEffect(() => {
    if (isSuccess) {
      getCompletedStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isFetching]);

  return (
    <>
      {kyc?.status !== ImmersveStageStatus.OK && !isAcceptanceValid && (
        <SupportedRegions regions={supportedRegions as Country[]}>
          <Text size="sm">
            Joinn supports the onboarding and KYC of Users who are both a
            citizen and a resident of the following countries.
          </Text>
        </SupportedRegions>
      )}
      <Card
        radius="lg"
        className="relative z-10 flex h-[-webkit-fill-available] min-h-[calc(100vh_-_340px)] flex-col items-center gap-20 py-14 max-md:items-start max-md:gap-2"
      >
        <Stepper
          steps={steps}
          activeStep={activeStep}
          completedStep={completedStep}
          onSelectStep={setActiveStep}
        />

        {activeStep === 1 && (
          <Information
            navButtons={getNavButtons()}
            isStepCompleted={isAcceptanceValid}
          />
        )}

        {activeStep === 2 && (
          <Activation navButtons={getNavButtons()} isStepCompleted={false} />
        )}

        {activeStep === 3 && (
          <Verification
            navButtons={getNavButtons()}
            isStepCompleted={false}
            kyc={kyc}
            user={user?.kyc || null}
          />
        )}

        {activeStep === 4 && <SuccessPage />}
      </Card>
    </>
  );
}

import { useAddressToName, useDashboard, useFormatToken } from "@/hooks";
import { useTransactionHistoryQuery } from "@/redux/transactionHistory/transactionHistory.api";
import { Routes } from "@/routes/routers";
import { useNavigate } from "react-router-dom";
import { Button } from "../ui";
import { ActivityCard } from "./activityCard";
import { ActivityTile } from "./activityTile";
import { ActivityTileSkeleton } from "./activityTileSkeleton";

export function ActivityPanel() {
  const { adjustSignAndFormat } = useFormatToken();
  const { getContractName } = useAddressToName();
  const { userWallets } = useDashboard();
  const navigate = useNavigate();

  const joinnWalletAddress = userWallets?.joinnWalletAddress;
  const { data: historyTransactionData, isFetching } =
    useTransactionHistoryQuery(
      {
        page: 0,
        limit: 5,
        joinnWalletAddress,
      },
      {
        skip: !joinnWalletAddress,
      },
    );
  const historyTransaction = historyTransactionData?.bofWallets?.[0]?.history;

  function handleNavigation(route: string) {
    navigate(route);
  }

  return (
    <ActivityCard title="Recent Activity" route={Routes.HISTORY}>
      {isFetching && (
        <>
          <ActivityTileSkeleton />
          <ActivityTileSkeleton />
          <ActivityTileSkeleton />
        </>
      )}

      {!isFetching &&
        historyTransaction &&
        (historyTransaction.length > 0 ? (
          // (false ? (
          <>
            {historyTransaction
              ?.slice(0, 5)
              .map((transaction) => (
                <ActivityTile
                  key={transaction.transactionHash}
                  date={transaction.blockTimestamp}
                  caption={transaction.type.toLowerCase()}
                  description={getContractName(transaction.from)}
                  amount={
                    adjustSignAndFormat(transaction.amount, transaction.type) +
                    " USDC"
                  }
                />
              ))}
            <Button
              variant="ghost"
              className="p-0 text-primary"
              size="sm"
              onClick={() => handleNavigation(Routes.HISTORY)}
            >
              Show more
            </Button>
          </>
        ) : (
          <div className="flex h-10 items-center justify-center text-accent">
            No recent history
          </div>
        ))}
    </ActivityCard>
  );
}

import "@/App.css";
import App from "@/App.tsx";
import "@/index.css";
import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";

Sentry.init({
  dsn: "https://04a877e2c8ac45faed62f065f21a46cc@o4508290889351168.ingest.us.sentry.io/4508295884308480",
  integrations: [],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  normalizeDepth: 10,
  maxValueLength: 1024,
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

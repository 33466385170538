import { config } from "@/config";

enum SupportedChains {
  TRN = "trn",
  POLYGON = "polygon",
}

interface SupportedChain {
  name: SupportedChains;
  chainId: number;
}

export const supportedChains: SupportedChain[] = [
  {
    name: SupportedChains.TRN,
    chainId: config.BLOCKCHAIN_NET === "mainnet" ? 7668 : 7672,
  },
  {
    name: SupportedChains.POLYGON,
    chainId: config.BLOCKCHAIN_NET === "mainnet" ? 137 : 80002,
  },
];

export const supportedChainsForFp: SupportedChain[] = supportedChains.filter(
  (chain) => chain.name === SupportedChains.TRN,
);

export function isChainSupported(
  nameOrChainId?: SupportedChains | number | null,
): boolean {
  if (!nameOrChainId) return false;
  return supportedChains.some(
    (chain: SupportedChain) =>
      chain.name === nameOrChainId || chain.chainId === nameOrChainId,
  );
}

export function isChainSupportedForFp(
  nameOrChainId?: SupportedChains | number | null,
): boolean {
  if (!nameOrChainId) return false;
  return supportedChainsForFp.some(
    (chain: SupportedChain) =>
      chain.name === nameOrChainId || chain.chainId === nameOrChainId,
  );
}

import { getMaskedAddress } from "@/lib/utils";
import { BalancesResponse } from "@/services/interfaces";
import { useEffect, useState } from "react";
import { useDashboard } from "./useDashboard";

type CombinedData = {
  address: string | undefined;
  name: string;
  balance: number | undefined;
};

export function useAddressToName() {
  const [combinedData, setCombinedData] = useState<CombinedData[] | []>([]);
  const { balances } = useDashboard();

  function combineAddressesAndNames(data: BalancesResponse | undefined) {
    const { vaults, wallets } = data || {};
    if (!vaults || !wallets) return [];

    const combined = [
      ...vaults.map((item) => ({
        address: item.address,
        name: item.name,
        balance: item.balance,
      })),
      ...wallets.map((item) => ({
        address: item.joinnAddress,
        name: item.name,
        balance: item.balance,
      })),
    ];

    return combined;
  }
  useEffect(() => {
    if (!balances) return;
    setCombinedData(combineAddressesAndNames(balances));
  }, [balances]);

  function getContractFromName(name: string) {
    if (name.toLowerCase() === "all") return;
    const addressWithName = combinedData.find(
      (item) => item.name?.toLowerCase() === name.toLowerCase(),
    );

    return addressWithName?.address;
  }

  function getContractName(searchAddress?: string) {
    if (!searchAddress) return;
    const vault = balances?.vaults.find(
      (vault) => vault.address?.toLowerCase() === searchAddress.toLowerCase(),
    );

    if (vault) return vault.name;

    const wallet = balances?.wallets.find(
      (wallet) =>
        wallet.joinnAddress?.toLowerCase() === searchAddress.toLowerCase(),
    );

    if (wallet?.name) return wallet.name;
    return wallet?.address
      ? getMaskedAddress(wallet?.address)
      : getMaskedAddress(searchAddress);
  }

  return {
    combinedData,
    getContractFromName,
    getContractName,
  };
}

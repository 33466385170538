import OnlineIcon from "@/assets/icons/online.svg";
import FuturePassIcon from "@/assets/images/futurepass.svg";
import { CopyButton } from "@/components";
import { Text } from "@/components/typography";
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Separator,
} from "@/components/ui";
import { WalletProvider } from "@/const";
import {
  Connector,
  useConnectorIcon,
  useLogout,
  useTrnNativeApi,
} from "@/hooks";
import { getMaskedAddress } from "@/lib/utils";
import { useUser } from "@/redux/user/user.slice";
import { isChainSupportedForFp } from "@/utils/supportedChains";
import { useAuth, useFutureverseSigner } from "@futureverse/auth-react";
import { useEffect, useMemo, useState } from "react";
import { useAccount } from "wagmi";

export default function WalletAccount() {
  const { userSession, signIn } = useAuth();
  const { holder: fpAddress } = useTrnNativeApi();
  const { address = "", chain, chainId, connector } = useAccount();
  const { walletProvider, setWalletProvider } = useUser();
  const { disconnect } = useLogout();

  const signer = useFutureverseSigner();

  const { path } = useConnectorIcon({
    name: (connector?.name as Connector) || Connector.Injected,
  });

  const [isFvIsLoading, setIsFvIsLoading] = useState<boolean>(false);

  function handleSwitchBetweenWallets() {
    if (walletProvider === WalletProvider.EOA) {
      setWalletProvider(WalletProvider.FP);
    } else {
      setWalletProvider(WalletProvider.EOA);
    }
  }

  async function handleFutureVerseConnect() {
    setIsFvIsLoading(true);
    try {
      await signIn(
        {
          type: "eoa",
          address: address,
          signer,
        },
        "popup",
      );
    } catch (error) {
      console.error("Failed to connect to FuturePass", error);
    }
    setIsFvIsLoading(false);
  }

  const allowFpConnection = useMemo(() => {
    return isChainSupportedForFp(chainId);
  }, [chainId]);

  useEffect(() => {
    if (!allowFpConnection && walletProvider === WalletProvider.FP) {
      setWalletProvider(WalletProvider.EOA);
    }
  }, [allowFpConnection, walletProvider, setWalletProvider]);

  return (
    <div className="flex items-center">
      <DropdownMenu>
        <DropdownMenuTrigger variant="outline">
          <div className="flex items-center">
            <div className="rounded-l-lg bg-accent/50 p-1">
              <img
                src={
                  walletProvider === WalletProvider.EOA ? path : FuturePassIcon
                }
                className="h-6 w-6 opacity-75"
                alt="wallet icon"
              />
            </div>
            <Text className="px-2.5 py-1 max-[350px]:px-1.5" size="sm">
              {getMaskedAddress(
                walletProvider === WalletProvider.EOA ? address : fpAddress,
              )}
            </Text>
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-[20rem] p-0">
          <Text size="xl" padding="lg">
            Account
          </Text>
          <Separator />
          <div className="p-5">
            <div className="flex items-start pb-5">
              <img src={OnlineIcon} className="mr-2 mt-1" alt="Online Icon" />
              <div>
                <Text variant="primary">{chain?.name}</Text>
                <Text>Chain Id: {chainId}</Text>
              </div>
            </div>
            <div className="pb-5">
              <DropdownMenuItem
                variant="secondary"
                className={`${walletProvider === WalletProvider.EOA ? "" : "translate-y-[62px] opacity-35"} mb-2 flex items-center justify-between transition-transform`}
                disableHover
                onClick={(event) => {
                  event?.preventDefault();
                }}
              >
                <div className="flex w-48 items-center gap-5">
                  <img src={path} className="h-6 w-6" alt="connector icon" />
                  <div>
                    <Text size="xs">{connector?.name}</Text>
                    <Text size="xs">{getMaskedAddress(address)}</Text>
                  </div>
                </div>
                <CopyButton text={address} />
              </DropdownMenuItem>

              {fpAddress && userSession && allowFpConnection && (
                <DropdownMenuItem
                  className={`${walletProvider === WalletProvider.FP ? "translate-y-[-3.875rem]" : "opacity-35"} flex items-center justify-between transition-transform`}
                  variant="secondary"
                  disableHover
                  onClick={(event) => {
                    event?.preventDefault();
                  }}
                >
                  <div className="flex w-48 items-center gap-5">
                    <img
                      src={FuturePassIcon}
                      alt="FuturePass icon"
                      className="h-6 w-6"
                    />
                    <div>
                      <Text size="xs">FuturesPass</Text>
                      <Text size="xs">{getMaskedAddress(fpAddress)}</Text>
                    </div>
                  </div>
                  <CopyButton text={fpAddress} />
                </DropdownMenuItem>
              )}
            </div>
            <div className="flex items-center justify-end gap-2">
              <Button variant="outline" size="sm" onClick={disconnect}>
                Disconnect
              </Button>

              {userSession && fpAddress && allowFpConnection && (
                <Button
                  onClick={handleSwitchBetweenWallets}
                  variant="primary"
                  size="sm"
                >
                  {walletProvider === WalletProvider.EOA
                    ? `Switch to ${connector?.name}`
                    : "Switch to FuturePass"}
                </Button>
              )}
              {!userSession && allowFpConnection && (
                <Button
                  variant="primary"
                  size="sm"
                  onClick={handleFutureVerseConnect}
                  disabled={isFvIsLoading}
                >
                  {`${fpAddress ? "Connect" : "Create"} FuturePass`}
                </Button>
              )}
            </div>
          </div>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}

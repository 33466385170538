export const US_TREASURY_ASSETS = [
  {
    label: "Asset Details",
    id: "asset-details",
    properties: [
      "description",
      "token_address",
      "asset_type",
      "redemption_asset_blockchain",
      "redemption_asset_ticker",
      "redemption_asset_address",
      "blockchain",
    ],
  },
  {
    label: "Issuer",
    id: "issuer",
    properties: ["issuer_name", "issuer_address", "issuer_url"],
  },
  {
    label: "Guardian",
    id: "guadian",
    properties: ["guardian_name", "guardian_address", "guardian_url"],
  },
  {
    label: "Agent",
    id: "agent",
    properties: ["agent_name", "agent_address", "agent_url"],
  },
  {
    label: "Custodian",
    id: "custodian",
    properties: ["custodian_name", "custodian_url"],
  },
  {
    label: "Audits",
    id: "audits",
    properties: ["swarm_audits", "joinn_audits"],
  },
];

export const AUTOMATED_LENDING_ASSET = [
  {
    label: "Asset Details",
    id: "asset-details",
    properties: [
      "deposit_asset",
      "redemption_asset",
      "blockchain",
      "vault_address",
      "strategy_address",
      "kya_version",
    ],
  },
  {
    label: "Third Party Protocols Interacted With",
    id: "third_party_protocol",
    properties: ["protocol"],
  },
  {
    label: "Audits",
    id: "audits",
    properties: ["aave_audits", "joinn_audits"],
  },
];

export const BDC_INCOME_ASSETS = [
  {
    label: "Asset Details",
    id: "asset-details",
    properties: [
      "description",
      "token_address",
      "strategy_address",
      "asset_type",
      "redemption_asset_blockchain",
      "redemption_asset_ticker",
      "blockchain",
      "token_standard",
      "token_version",
      "kya_version",
    ],
  },
  {
    label: "Third Party Protocols Interacted With",
    id: "third_party_protocol",
    properties: ["protocols"],
  },
  {
    label: "Audits",
    id: "audits",
    properties: ["aave_audits", "quickswap_audits", "joinn_audits"],
  },
];

export const SPENDING_ACCOUNT_ASSET = [
  {
    label: "Asset Details",
    id: "asset-details",
    properties: [
      "deposit_asset",
      "redemption_asset",
      "blockchain",
      "contract_address",
      "kya_version",
    ],
  },
  {
    label: "Third Party Protocols Interacted With",
    id: "third_party_protocol",
    properties: ["protocol"],
  },
  {
    label: "Audits",
    id: "audits",
    properties: ["immersve_audits", "joinn_audits"],
  },
];

import JoinnLogo from "@/assets/images/joinnlogo.svg";
import JoinnWordMark from "@/assets/images/joinnworkmark.svg";
import { Box, BoxContent, Link, Text } from "@/components";
import {
  Button,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui";
import { Connector, useConnectorIcon, useLogin } from "@/hooks";
import { cn } from "@/lib/utils";
import { useState } from "react";
import { useConnectors } from "wagmi";

export function Login() {
  const connectors = useConnectors();

  const { getIcon } = useConnectorIcon();
  const { connect, isConnecting, connector: activeConnector } = useLogin();

  const [walletId, setWalletId] = useState("");

  return (
    <div className="flex min-h-screen flex-col items-center justify-center gap-10 bg-background p-5">
      <div className="relative flex items-center justify-center">
        <img
          src={JoinnLogo}
          alt="Joinn Login Logo"
          className="w-full max-w-screen-md animate-fade-in"
        />
        <img
          src={JoinnWordMark}
          alt="Joinn Wordmark Logo"
          className="absolute w-1/2 max-w-screen-sm animate-fade-in delay-300"
        />
      </div>
      <Text size="md" className="animate-fade-in delay-500">
        Connect with:
      </Text>
      <div className="flex w-full animate-fade-in flex-wrap justify-center gap-5 delay-500">
        {connectors
          .filter((connector) => {
            // filtering out duplicate metamask
            return (
              connector.type !== "injected" ||
              (connector.type === "injected" && connector.id === "metaMask")
            );
          })
          .map((connector) => {
            return (
              <Box
                key={connector.id}
                innerBox="h-full"
                radius="lg"
                className={cn(
                  "h-24 max-w-44 from-secondary/50 to-accent shadow-lg transition-all hover:from-secondary hover:to-primary hover:px-1",
                  isConnecting &&
                    (activeConnector?.id === connector.id ||
                      walletId === connector.id) &&
                    "animate-bounce from-secondary to-primary px-1",
                )}
                onClick={() => {
                  setWalletId(connector.id);
                  connect(connector);
                }}
              >
                <BoxContent variant="button" className="">
                  <Button className="grid justify-items-center text-nowrap hover:opacity-100">
                    <img
                      src={getIcon(connector.name as Connector)}
                      height={24}
                      width={24}
                      alt={`${connector.name} Icon`}
                    />
                    {connector.name}
                  </Button>
                </BoxContent>
              </Box>
            );
          })}
      </div>
      <TooltipProvider delayDuration={100}>
        <Text size="md" className="animate-fade-in text-center delay-700">
          By using Joinn, you agree to our{" "}
          <Tooltip>
            <TooltipTrigger>
              {/* TODO: Make this a Link and remove tooltip when the URL is available */}
              <span className="underline">Terms of Service</span>
            </TooltipTrigger>
            <TooltipContent>
              <Text>Coming soon!</Text>
            </TooltipContent>
          </Tooltip>{" "}
          and our{" "}
          <Link
            to="https://info.joinn.io/privacypolicy/"
            target="_blank"
            className="underline"
          >
            Privacy Policy
          </Link>
          .
        </Text>
      </TooltipProvider>
    </div>
  );
}

import { Link, Text } from "@/components";
import { Separator } from "@/components/ui";
import { IMMERSVE_GENERAL_USE, SPENDING_ACCOUNT } from "@/const";
import { useIcons } from "@/hooks";
import { AccordionCard } from "./accordionCard";

export function AccountOverviewCard() {
  const { ShareIcon } = useIcons();
  return (
    <AccordionCard id="account-overview" label="Account Overview">
      <Text size="sm">
        Your Spending Account enables you to create and link a digital
        Mastercard, allowing you to spend your digital assets anywhere in the
        world Mastercard is accepted. You can also add your BoF Mastercard
        details to Google Pay and Apple Pay.
      </Text>
      <div className="grid gap-3">
        <Text size="sm" variant="primary">
          Additional Information
        </Text>
        <Text size="sm">
          This Spending Account is a smart contract that is unique to you. When
          you create a Mastercard it automatically links it to your unique smart
          contract and allows you to spend the balance of funds that are sitting
          in your Spending Account.
        </Text>
        <Text size="sm">
          Your funds remain in USDC until a purchase is initiated. At that
          point, a foreign exchange rate is applied based on the currency of the
          item you are purchasing and any other associated fees. You can make
          purchases worldwide in the currencies listed in the Currency
          Calculator below, along with their corresponding real-time conversion
          rates.
        </Text>
      </div>
      <div className="grid gap-6">
        <Text size="lg" className="font-semibold">
          FAQ's
        </Text>
        <Separator />
        <div className="grid gap-3">
          <Text size="sm" variant="primary">
            What happens when I make a purchase?
          </Text>
          <Text size="sm">
            When a purchase is made the Mastercard Network first checks to see
            if you have available funds sitting in your unique smart contract
            (Spending Account). If so, a currency conversion rate and any other
            associated fees are applied and the respective USDC amount is
            released for ultimate settlement with the Mastercard Network.
          </Text>
        </div>
        <div className="grid gap-3">
          <Text size="sm" variant="primary">
            Can I check to see the estimated amount of USDC required including
            fees before I make a purchase?
          </Text>
          <Text size="sm">
            Yes, using the Currency Calculator below you will be able to see the
            estimated amount of USDC required inclusive of any currency exchange
            fees and other associated fees that may apply.
          </Text>
        </div>
        <div className="grid gap-3">
          <Text size="sm" variant="primary">
            Can I cancel my card?
          </Text>
          <Text size="sm">
            Yes, you can cancel your card at any time and create a new one
            instantly.
          </Text>
        </div>
        <div className="grid gap-2">
          <Link
            to={IMMERSVE_GENERAL_USE}
            target="_blank"
            className="flex gap-4 text-base text-primary underline max-sm:text-sm"
          >
            Mastercard general terms of use <ShareIcon />
          </Link>
          <Link
            to={SPENDING_ACCOUNT}
            target="_blank"
            className="flex gap-4 text-base text-primary underline max-sm:text-sm"
          >
            More Information <ShareIcon />
          </Link>
        </div>
      </div>
    </AccordionCard>
  );
}

import TrnNativeApiContext, {
  TrnNativeApiContextType,
} from "@/context/trnNativeApi.context";
import { ApiPromise } from "@polkadot/api";
import { getApiOptions, getPublicProvider } from "@therootnetwork/api";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Hex } from "viem";
import { useAccount } from "wagmi";
import { config } from "../config";

interface PropTypes {
  children: React.ReactNode;
}

function TrnNativeApiProvider({ children }: Readonly<PropTypes>) {
  const { address } = useAccount();

  const [api, setApi] = useState<ApiPromise | null>(null);
  const [holder, setHolder] = useState<Hex | null>(null);

  useLayoutEffect(() => {
    const createApiInstance = async () => {
      const apiInstance = await ApiPromise.create({
        ...getApiOptions(),
        ...getPublicProvider(
          config.BLOCKCHAIN_NET === "mainnet" ? "root" : "porcini",
        ),
      });
      setApi(apiInstance);
    };

    createApiInstance();
  }, []);

  const getHolder = useCallback(async () => {
    if (!api || !address) {
      console.error("API not initialized or address not available");
      return;
    }

    try {
      const holdersData = await api.query.futurepass.holders(address);
      setHolder(holdersData.toHuman() as Hex | null);
    } catch (error) {
      setHolder(null);
      console.error("Error fetching holders:", error);
    }
  }, [api, address]);

  useEffect(() => {
    if (!api || !address) return;
    getHolder();
  }, [address, api, getHolder]);

  const value: TrnNativeApiContextType = useMemo(() => {
    return {
      api,
      holder,
    };
  }, [api, holder]);

  return (
    <TrnNativeApiContext.Provider value={value}>
      {children}
    </TrnNativeApiContext.Provider>
  );
}

export default TrnNativeApiProvider;

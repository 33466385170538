import HistoryLayout from "@/layouts/History.layout";
import PrivateLayout from "@/layouts/PrivateLayout";
import {
  AccountInformation,
  AdminPanel,
  Login as Connect,
  Dashboard,
  ErrorElement,
  FvEstablishSession,
  KnowYourCustomer,
  Orders,
} from "@/pages";
import { CardEnrollment, JoinnAccounts, Mastercard } from "@/pages/History";
import { Suspense } from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";

export enum Routes {
  ADMIN = "/admin/dashboard",
  DASHBOARD = "/",
  CONNECT = "/connect",
  HISTORY = "/history",
  JOINN_ACCOUNTS = "joinn-accounts",
  MASTERCARD = "mastercard",
  CARD_ENROLLMENT = "card-enrollment",
  ORDERS = "/orders",
  TRANSACTION = "transaction/:type",
  DEPOSIT = "/deposit",
  TRANSFER = "/transfer",
  WITHDRAW = "/withdraw",
  ACCOUNT_INFORMATION = "account/:account",
  KYC = "/kyc",
  IMMERSVE_KYC = "/immersve-kyc",
  FV_ESTABLISH_SESSION = "/fv-establish-session",
  ADMIN_PANEL = "/admin-panel",
  MASTERCARD_ACTIVATION = "/mastercard-activation",
}

export const routers = createBrowserRouter([
  {
    path: "/",
    errorElement: <ErrorElement />,
    children: [
      {
        path: Routes.FV_ESTABLISH_SESSION,
        element: <FvEstablishSession />,
      },
      {
        path: Routes.CONNECT,
        element: <Connect />,
      },

      {
        element: (
          <Suspense fallback={<></>}>
            <PrivateLayout />
          </Suspense>
        ),
        children: [
          {
            path: Routes.KYC,
            element: <KnowYourCustomer />,
          },
          {
            path: Routes.IMMERSVE_KYC,
            element: <KnowYourCustomer />,
          },
          {
            path: Routes.DASHBOARD,
            element: <Dashboard />,
            children: [
              {
                path: Routes.TRANSACTION,
                element: <></>,
              },
              {
                path: Routes.MASTERCARD_ACTIVATION,
                element: <></>,
              },
            ],
          },
          {
            path: Routes.HISTORY,
            element: <HistoryLayout />,
            children: [
              {
                index: true,
                element: <Navigate to={Routes.JOINN_ACCOUNTS} replace />,
              },
              {
                path: Routes.JOINN_ACCOUNTS,
                element: <JoinnAccounts />,
                children: [
                  {
                    path: Routes.TRANSACTION,
                    element: <></>,
                  },
                ],
              },
              {
                path: Routes.MASTERCARD,
                element: <Mastercard />,
                children: [
                  {
                    path: Routes.TRANSACTION,
                    element: <></>,
                  },
                ],
              },
              {
                path: Routes.CARD_ENROLLMENT,
                element: <CardEnrollment />,
                children: [
                  {
                    path: Routes.TRANSACTION,
                    element: <></>,
                  },
                ],
              },
            ],
          },
          {
            path: Routes.ORDERS,
            element: <Orders />,
            children: [
              {
                path: Routes.TRANSACTION,
                element: <></>,
              },
            ],
          },
          {
            path: Routes.ACCOUNT_INFORMATION,
            element: <AccountInformation />,
          },
          {
            path: Routes.ADMIN_PANEL,
            element: <AdminPanel />,
          },
          {
            path: "*",
            element: <Navigate to={Routes.DASHBOARD} replace />,
          },
        ],
      },
    ],
  },
]);

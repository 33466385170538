import { TransactionsStatus } from "@/const";
import { Button } from "@/components/ui";

type ActionButtonsProps = {
  status: TransactionsStatus;
  isProcessing: boolean;
  isError: boolean;
  type: "panel" | "modal";
  onClose: () => void;
  onTransact: () => void;
  transactionLabel: string;
};

export function ActionButtons(props: Readonly<ActionButtonsProps>) {
  const {
    status,
    type,
    onClose,
    onTransact,
    isProcessing,
    isError,
    transactionLabel,
  } = props;

  return (
    <div className="mt-8 flex justify-end gap-2">
      {status === TransactionsStatus.SUCCESS && type === "modal" ? (
        <Button
          variant="outline"
          size="md"
          disabled={isProcessing}
          onClick={onClose}
        >
          Close
        </Button>
      ) : (
        <>
          <Button
            variant="outline"
            size="md"
            disabled={isProcessing}
            onClick={onClose}
          >
            {type === "modal" ? "Cancel" : "Clear"}
          </Button>
          <Button
            type="button"
            variant="primary"
            size="md"
            onClick={onTransact}
            disabled={isProcessing || !!isError}
          >
            {transactionLabel}
          </Button>
        </>
      )}
    </div>
  );
}

import ContactUsIcon from "@/assets/icons/contactus.svg";
import FaqIcon from "@/assets/icons/faq.svg";
import SupportCallIcon from "@/assets/icons/headphones.svg";
import LogoutIcon from "@/assets/icons/logout.svg";
import FeedbackIcon from "@/assets/icons/thumbup.svg";
import UserIcon from "@/assets/icons/user.svg";
import UserUnverifiedIcon from "@/assets/icons/userunverified.svg";
import UserVerifiedIcon from "@/assets/icons/userverified.svg";
import { Text } from "@/components";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Separator,
} from "@/components/ui";
import { CONTACT_US, FEED_BACK, INFO, SUPPORT_JOINN } from "@/const";
import { KYCStatus } from "@/const/kycStatus";
import { useIcons, useLogout, useSpending } from "@/hooks";
import { getMaskedAddress, routeToUrl } from "@/lib/utils";
import { useImmersveState } from "@/redux/immersve/immersve.slice";
import { useModalState } from "@/redux/modal/modal.slice";
import { useUserProfileQuery } from "@/redux/user/user.api";
import { Routes } from "@/routes/routers";
import { Check, X } from "lucide-react";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import AccountInformation from "./AccountInformation";
import ContactDetails from "./ContactDetails";

export default function UserProfile() {
  const { data: user } = useUserProfileQuery();
  const { ShareIcon, MastercardIcon } = useIcons();
  const { disconnect } = useLogout();
  const { showSpending } = useImmersveState();
  const { toggleModal } = useModalState();
  const { isSpendingHidden } = useSpending();

  const navigate = useNavigate();

  function logout() {
    disconnect();
  }

  const isKycApproved = useMemo(() => {
    return user?.kyc?.status === KYCStatus.APPROVED;
  }, [user?.kyc?.status]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger variant="outline" arrow={false}>
        <div className="relative rounded-full bg-accent/50 p-1">
          <img
            src={user?.avatarUrl ?? UserIcon}
            className="h-6 w-6 rounded-[inherit]"
            alt="User Avatar Icon"
          />
          {isKycApproved ? (
            <Check
              color="lime"
              className="absolute -bottom-2 -right-2 rounded-full bg-green-800 p-1"
              strokeWidth={3}
              size={20}
            />
          ) : (
            <X
              color="white"
              className="absolute -bottom-2 -right-2 rounded-full bg-red-700/75 p-1"
              strokeWidth={3}
              size={20}
            />
          )}
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="max-h-[80vh] overflow-y-scroll p-0 min-[420px]:w-[20rem]">
        <div className="flex items-center rounded-lg p-5">
          <img
            src={user?.avatarUrl ?? UserIcon}
            className="h-12 w-12 rounded-full bg-accent/50 p-1"
            alt="User Avatar Icon"
          />
          <div className="pl-3">
            <Text size="lg">
              {user?.name ?? getMaskedAddress(user?.address)}
            </Text>
            <Text size="md">{user?.email}</Text>
          </div>
        </div>

        <Separator />
        <div className="flex flex-col gap-4 p-5">
          <DropdownMenuItem
            onClick={() => {
              navigate(Routes.KYC);
            }}
          >
            {/* Use Text instead of Link so the onClick handler does not interfere with DropdownMenuItem */}
            <Text size="md" className="flex w-full gap-3 hover:text-primary">
              <img
                src={
                  user?.kyc?.status === "APPROVED"
                    ? UserVerifiedIcon
                    : UserUnverifiedIcon
                }
                alt="User KYC Verification Icon"
                className="w-5"
              />{" "}
              Onboarding and KYC
            </Text>
          </DropdownMenuItem>
          {isSpendingHidden && (
            <DropdownMenuItem
              onClick={() => {
                showSpending();
                toggleModal({
                  id: "pre-kyc-modal",
                  isFullWidth: true,
                });
              }}
            >
              <Text
                size="md"
                className="-ml-1 flex w-full gap-2 hover:text-primary"
              >
                <MastercardIcon size={28} />
                Create Mastercard
              </Text>
            </DropdownMenuItem>
          )}
        </div>

        <Separator />
        <div className="flex flex-col gap-4 p-5">
          <ContactDetails user={user} />
          <AccountInformation user={user} />
        </div>

        <Separator />
        <DropdownMenuItem
          className="flex items-center gap-3 p-5 hover:text-primary"
          onClick={() => {
            routeToUrl(INFO);
          }}
        >
          <img src={FaqIcon} className="w-5" alt="Information Icon" />
          <div className="flex w-full items-center justify-between text-inherit">
            <Text size="md" className="text-inherit">
              Information
            </Text>
            <ShareIcon />
          </div>
        </DropdownMenuItem>

        <Separator />
        <div className="flex flex-col gap-4 p-5">
          <DropdownMenuItem
            className="flex items-center gap-3 hover:text-primary"
            onClick={() => {
              routeToUrl(CONTACT_US);
            }}
          >
            <img src={ContactUsIcon} className="w-5" alt="Contact Us Icon" />
            <div className="flex w-full items-center justify-between text-inherit">
              <Text size="md" className="text-inherit">
                Contact Us
              </Text>
              <ShareIcon />
            </div>
          </DropdownMenuItem>
          <DropdownMenuItem
            className="flex items-center gap-3 hover:text-primary"
            onClick={() => {
              routeToUrl(FEED_BACK);
            }}
          >
            <img src={FeedbackIcon} className="w-5" alt="Feedback Icon" />
            <div className="flex w-full items-center justify-between text-inherit">
              <Text size="md" className="text-inherit">
                Feedback
              </Text>
              <ShareIcon />
            </div>
          </DropdownMenuItem>
          <DropdownMenuItem
            className="flex items-center gap-3 hover:text-primary"
            onClick={() => {
              routeToUrl(SUPPORT_JOINN);
            }}
          >
            <img
              src={SupportCallIcon}
              className="w-5"
              alt="Telegram Chat Icon"
            />
            <div className="flex w-full items-center justify-between text-inherit">
              <Text size="md" className="text-inherit">
                Telegram Chat
              </Text>
              <ShareIcon />
            </div>
          </DropdownMenuItem>
        </div>

        <Separator />
        <DropdownMenuItem
          className="flex items-center gap-3 p-5 hover:text-primary"
          onClick={logout}
        >
          <img src={LogoutIcon} className="w-5" alt="Logout Icon" />
          <Text size="md" className="text-inherit">
            Logout
          </Text>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

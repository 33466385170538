import { Card, Separator, Tabs, TabsList, TabsTrigger } from "@/components/ui";
import { Routes } from "@/routes/routers";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export default function HistoryLayout() {
  const transactionMenuItems = [
    {
      id: 1,
      title: "Joinn Accounts",
      to: Routes.JOINN_ACCOUNTS,
    },
    {
      id: 2,
      title: "Mastercard",
      to: Routes.MASTERCARD,
    },
    {
      id: 3,
      title: "Card Enrollment",
      to: Routes.CARD_ENROLLMENT,
    },
  ];
  const navigate = useNavigate();
  const location = useLocation();

  const activeTab =
    transactionMenuItems
      .find((item) => location.pathname.includes(item.to))
      ?.id.toString() ?? "1";

  return (
    <Card
      variant="container"
      className="col-span-3 my-5 lg:col-span-2"
      radius="lg"
      size="md"
    >
      <Card radius="md">
        <h1 className="text-2xl">Transaction History</h1>
        <div className="mb-3 mt-8 flex items-center justify-between gap-4 max-[460px]:flex-col max-[460px]:gap-8">
          <Tabs
            value={activeTab}
            onValueChange={(value) => {
              const selectedItem = transactionMenuItems.find(
                (item) => item.id.toString() === value,
              );
              if (selectedItem) {
                navigate(selectedItem.to);
              }
            }}
          >
            <TabsList className="flex-nowrap max-[460px]:flex-wrap max-[460px]:gap-0">
              {transactionMenuItems.map((item) => (
                <TabsTrigger
                  key={item.id}
                  value={item.id.toString()} // Match tab value with id
                  className="h-full"
                >
                  {item.title}
                </TabsTrigger>
              ))}
            </TabsList>
            {/* </Flex> */}
          </Tabs>
          <Separator orientation="horizontal" className="shrink" />
        </div>
        <Outlet />
      </Card>
    </Card>
  );
}

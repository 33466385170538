import { Spinner, Text } from "@/components";
import { Button, Card, Skeleton } from "@/components/ui";
import { useSpending } from "@/hooks";
import { cn } from "@/lib/utils";
import { useModalState } from "@/redux/modal/modal.slice";
import { Routes } from "@/routes/routers";
import { useNavigate } from "react-router-dom";

type PreKycProps = {
  isConnecting: boolean;
};

export function PreKycSpending(props: Readonly<PreKycProps>) {
  const { isConnecting } = props;
  const { toggleModal } = useModalState();
  const { hasFundingSource } = useSpending();
  const navigate = useNavigate();

  return (
    <Card radius="md" size="md" className="flex flex-col gap-3">
      <Text size="2xl" className="font-bold">
        Spending Account
      </Text>
      <div className="relative">
        <Skeleton
          className={cn(
            "absolute block h-full w-full",
            !isConnecting && "hidden",
          )}
        />
        <Text
          size="sm"
          className={cn("text-foreground/75", isConnecting && "invisible")}
        >
          Your Spending Account allows you to instantly create and link a
          digital Mastercard, allowing you to spend your digital assets anywhere
          in the world where Mastercard is accepted.
        </Text>
      </div>
      <div className="relative">
        <Skeleton
          className={cn(
            "absolute block h-full w-full",
            !isConnecting && "hidden",
          )}
        />
        <Text
          size="sm"
          className={cn("text-foreground/75", isConnecting && "invisible")}
        >
          Enjoy this service by creating a Spending Account and Mastercard now!
        </Text>
      </div>
      <div className="flex gap-3 self-end pt-5 max-sm:flex-col max-sm:items-end">
        <div className="relative">
          <Skeleton
            className={cn(
              "absolute block h-full w-full",
              !isConnecting && "hidden",
            )}
          />
          <Button
            variant="secondary"
            size="sm"
            className={cn(isConnecting && "invisible")}
            onClick={() => {
              toggleModal({
                id: "hide-spending",
                title: "Are you sure?",
              });
            }}
          >
            No, thanks!
          </Button>
        </div>

        <div className="relative">
          <Skeleton
            className={cn(
              "absolute block h-full w-full",
              !isConnecting && "hidden",
            )}
          />
          <Button
            variant="primary"
            size="sm"
            className={cn(isConnecting && "invisible")}
            onClick={() => {
              navigate(Routes.MASTERCARD_ACTIVATION);
              toggleModal({
                id: "pre-kyc-modal",
                isFullWidth: true,
                redirectBack: true,
              });
            }}
          >
            {hasFundingSource
              ? "Continue creation of Spending Account and Mastercard"
              : "Yes, I’d like to create a Spending Account and Mastercard"}
            {isConnecting && <Spinner />}
          </Button>
        </div>
      </div>
    </Card>
  );
}

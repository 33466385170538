export const JOINN_SUBGRAPH_URI =
  "https://api.studio.thegraph.com/query/68622/bof-aa/version/latest";

export const SUBGRAPH_URI: { [key: number]: string } = {
  137: "https://api.studio.thegraph.com/query/68622/bof-polygon/version/latest",
  80002: "https://api.studio.thegraph.com/query/68622/bof-aa/version/latest",
  7668: "https://graph-trn-mainnet.bof.xyz/subgraphs/name/bof/vault",
  7672: "https://graph-trn-testnet.bof.xyz/subgraphs/name/bof/vault",
  11155111:
    "https://api.studio.thegraph.com/query/68622/bof-sepolia/version/latest",
};

export const ACCEPTED_COUNTRIES = "https://docs.bof.xyz/user-access";
export const FRACTAL_ID = "https://web.fractal.id/";
export const FRACTAL_SECURITY =
  "https://fractal.freshdesk.com/support/solutions/folders/76000009565";
export const FRACTAL_PRIVACY_POLICY =
  "https://app.fractal.id/documents/41a635413a9fd3081492/privacy-policy-v11.pdf";
export const FRACTAL_EMAIL = "support@Fractal.id";

export const SUPPORT_JOINN = "https://t.me/JoinnFinance";
export const TWITTER_JOINN = "https://x.com/JoinnFinance";
export const PRIVACY_POLICY = "https://info.joinn.io/privacypolicy/";
export const CONTACT_US =
  "https://airtable.com/appFgDv0Ht9Z9Kmcj/pag1fZxcknsMQcYnU/form";
export const FEED_BACK =
  "https://airtable.com/appFgDv0Ht9Z9Kmcj/pagqqk4DDxxKr1UnJ/form";
export const INFO = "https://info.joinn.io/";

export const US_TREASURY_BONDS =
  "https://www.ishares.com/uk/individual/en/products/307243/ishares-treasury-bond-0-1yr-ucits-etf";

export const BDC_INCOME =
  "https://www.vaneck.com/us/en/investments/bdc-income-etf-bizd/overview/";

export const AUTOMATED_LENDING = "https://docs.bof.xyz/lending";

export const IMMERSVE_GENERAL_USE =
  "https://immersve.com/general-terms-of-use/";

export const IMMERSVE = "https://immersve.com/";

export const HELP_IMMERSVE = "https://help.immersve.com/hc/en-us/requests/new";

export const SPENDING_ACCOUNT =
  "https://docs.bof.xyz/bof-accounts/spending-account";

export const SWARM_AUDIT =
  "https://docs.swarm.com/reference/smart-contract-audit";

export const AAVE_AUDIT =
  "https://docs.aave.com/developers/deployed-contracts/security-and-audits";

export const QUICK_SWAP_AUDIT =
  "https://docs.quickswap.exchange/technical-reference/smart-contracts";

import InputField from "../inputField";
import { Button } from "../ui";

type TransferInputProps = {
  onChange: (value: number) => void;
  value: number;
  maxValue?: number;
};

export function TransferInput({
  onChange,
  value,
  maxValue = 0,
}: Readonly<TransferInputProps>) {
  function onMaxClick() {
    onChange(maxValue);
  }

  return (
    <InputField
      className="h-14 pl-4 text-base text-white max-sm:pl-3"
      label="Amount"
      placeholder="0.00"
      min={0}
      type="text"
      onKeyDown={handleOnKeyDown}
      onChange={(e) => {
        console.log(e.target.value);
        onChange(Number(e.target.value));
      }}
      value={value === 0 ? "" : value}
      endAdornment={
        <div className="flex items-center">
          <p className="text-primary-dark h-full pl-3 pr-3 text-accent">USDC</p>
          <Button
            onClick={onMaxClick}
            type="button"
            className="h-14 rounded-none rounded-r bg-secondary/10 px-4"
          >
            MAX
          </Button>
        </div>
      }
    />
  );
}

function handleOnKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
  const input = e.target as HTMLInputElement;
  const inputKey = e.key;
  const allowedKeys = ["Backspace", "Tab", "ArrowLeft", "ArrowRight", "Delete"];

  const [integerPart, decimalPart = ""] = input.value.split(".");

  if (
    (inputKey < "0" || inputKey > "9") &&
    inputKey !== "." &&
    inputKey !== "," &&
    !allowedKeys.includes(inputKey)
  ) {
    e.preventDefault();
    return;
  }

  if (inputKey === "," || inputKey === ".") {
    e.preventDefault();
    if (!input.value.includes(".")) {
      const cursorPosition = input.selectionStart ?? 0;
      const newValue =
        input.value.slice(0, cursorPosition) +
        "." +
        input.value.slice(cursorPosition);
      input.value = newValue;
      input.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
    }
    return;
  }

  if (
    integerPart.length >= 11 &&
    inputKey !== "Backspace" &&
    !allowedKeys.includes(inputKey)
  ) {
    e.preventDefault();
    return;
  }

  if (decimalPart.length >= 2 && !allowedKeys.includes(inputKey)) {
    e.preventDefault();
    return;
  }

  const currentValue =
    integerPart + (decimalPart.length > 0 ? "." + decimalPart : "");
  console.log("currentValue", currentValue);
}

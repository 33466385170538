import ScanLink from "@/assets/icons/link.svg";
import {
  Cell,
  Head,
  Header,
  HistoryDropdownFilter,
  HistoryShowMoreButton,
  HistoryTile,
  Row,
  Table,
} from "@/components/transactionHistory";
import { HistoryRowSkeleton } from "@/components/transactionHistory/HistoryRowSkeleton";
import { Separator } from "@/components/ui";
import { Accounts, Transactions } from "@/const";
import { useAddressToName, useDashboard, useFormatToken } from "@/hooks";
import { useLazyTransactionHistoryQuery } from "@/redux/transactionHistory/transactionHistory.api";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useAccount } from "wagmi";

export function JoinnAccounts() {
  const TRANSACTIONS_PER_PAGE = 10;
  const [page, setPage] = useState(0);
  const { userWallets } = useDashboard();
  const { chainId } = useAccount();

  const joinnWalletAddress = userWallets?.joinnWalletAddress;

  const [fetchTransactionHistory, { data, isFetching }] =
    useLazyTransactionHistoryQuery();

  const { getContractName, getContractFromName } = useAddressToName();
  const { adjustSignAndFormat, formatTokenAmount } = useFormatToken();

  const [transactionTypeFilter, setTransactionTypeFilter] =
    useState<Transactions>(Transactions.ALL);
  const [accountTypeFilter, setAccountTypeFilter] = useState<Accounts>(
    Accounts.ALL,
  );

  const AccountTypeOptions = [
    { id: "1", label: Accounts.ALL },
    { id: "2", label: Accounts.SPENDING_ACCOUNT },
    { id: "3", label: Accounts.AUTOMATED_LENDING },
    { id: "4", label: Accounts.BDC_INCOME },
    { id: "5", label: Accounts.US_TREASURY_BONDS },
  ];

  const TransactionTypeOptions = [
    { id: "1", label: Transactions.ALL },
    { id: "2", label: Transactions.DEPOSIT },
    { id: "3", label: Transactions.WITHDRAW },
    { id: "4", label: Transactions.TRANSFER },
  ];

  const joinnWallets = data?.bofWallets?.[0];

  const filteredTransactions = useMemo(() => {
    if (!joinnWallets?.history) return [];

    const filteredContract = getContractFromName(accountTypeFilter);

    return joinnWallets.history.filter((row) => {
      const matchesTransactionType =
        transactionTypeFilter === Transactions.ALL ||
        row.type.toLowerCase() === transactionTypeFilter.toLowerCase();

      const matchesAccountType =
        accountTypeFilter === Accounts.ALL ||
        row.from.toLowerCase() === filteredContract?.toLowerCase() ||
        row.to.toLowerCase() === filteredContract?.toLowerCase();

      return matchesTransactionType && matchesAccountType;
    });
  }, [
    joinnWallets,
    transactionTypeFilter,
    accountTypeFilter,
    getContractFromName,
  ]);

  useEffect(() => {
    setPage(0);
    if (joinnWalletAddress && chainId !== undefined) {
      fetchTransactionHistory({
        page: 0,
        limit: TRANSACTIONS_PER_PAGE,
        joinnWalletAddress,
      });
    }
  }, [chainId, joinnWalletAddress, fetchTransactionHistory]);

  const loadNextPage = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchTransactionHistory({
      page: nextPage,
      limit: TRANSACTIONS_PER_PAGE,
      joinnWalletAddress,
    });
  };

  return (
    <>
      <HistoryTile
        title="Joinn Accounts' Balance"
        balance={formatTokenAmount(joinnWallets?.balances?.[0]?.amount)}
      >
        <div className="flex flex-wrap items-center gap-4 sm:flex-nowrap">
          <HistoryDropdownFilter
            title="Account Type"
            label={accountTypeFilter}
            options={AccountTypeOptions}
            onSelectOption={(option) =>
              setAccountTypeFilter(option?.label as Accounts)
            }
          />
          <Separator orientation="vertical" className="hidden h-8 sm:block" />
          <HistoryDropdownFilter
            title="Transaction Type"
            label={transactionTypeFilter}
            options={TransactionTypeOptions}
            onSelectOption={(option) =>
              setTransactionTypeFilter(option?.label as Transactions)
            }
          />
        </div>
      </HistoryTile>

      <Table>
        <Head>
          <Header>Date</Header>
          <Header>Transaction</Header>
          <Header>From</Header>
          <Header>To</Header>
          <Header>Amount</Header>
        </Head>
        {joinnWallets?.history?.length === 0 && (
          <div className="flex w-full justify-center text-accent">
            There is no history yet
          </div>
        )}
        {filteredTransactions.map((row) => (
          <Row key={row.transactionHash + row.id}>
            <Cell caption="Date:" textStyles="text-accent">
              {dayjs.unix(Number(row.blockTimestamp)).format("MM/DD/YYYY")}
            </Cell>
            <Cell caption="Transaction">
              <span className="capitalize">{row.type.toLowerCase()}</span>
              <a
                href={`https://amoy.polygonscan.com/tx/${row.transactionHash}`}
                target="_blank"
                className="hover:opacity-60"
              >
                <img src={ScanLink} alt="scan" />
              </a>
            </Cell>
            <Cell caption="From:">{getContractName(row.from)}</Cell>
            <Cell caption="To: ">{getContractName(row.to)}</Cell>
            <Cell caption="Amount: ">
              {adjustSignAndFormat(row.amount, row.type)} USDC
            </Cell>
          </Row>
        ))}
      </Table>

      <HistoryRowSkeleton colsNumber={5} isLoading={isFetching} />

      {joinnWallets?.history &&
        joinnWallets?.history?.length > 0 &&
        filteredTransactions.length === 0 && (
          <div className="flex justify-center text-accent">
            No filtered results
          </div>
        )}
      {filteredTransactions.length > 0 && (
        <HistoryShowMoreButton
          isFetching={isFetching}
          loadNextPage={loadNextPage}
          transactionsLoaded={joinnWallets?.history?.length}
          transactionsCount={joinnWallets?.transactionsCount}
          page={page}
        />
      )}
    </>
  );
}

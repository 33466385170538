import ArrowDownIcon from "@/assets/icons/arrowdown.svg";
import { Text } from "@/components";
import { ExternalWallet } from "@/components/externalWallets";
import { Button, Card, Separator } from "@/components/ui";
import { useBalances, useDashboard } from "@/hooks";
import { Plus } from "lucide-react";
import { useAccount } from "wagmi";

export default function ExternalWallets() {
  const { userWallets } = useDashboard();
  const { address } = useAccount();
  const { vaultsWalletsBalancesSorted } = useBalances();

  const joinnWalletAddress = userWallets?.joinnWalletAddress;

  return (
    <Card className="flex flex-col gap-5" radius="md">
      <div className="flex items-center justify-between">
        <div className="flex flex-wrap items-center gap-5">
          <Button>
            <Text variant="primary" size="2xl">
              External Wallets
            </Text>
            <img
              className="relative top-[1px] ml-1 w-3 text-accent transition duration-200 group-data-[state=open]:rotate-180"
              src={ArrowDownIcon}
              alt="Arrow Icon"
            />
          </Button>
          <Separator orientation="vertical" className="h-8" />
          <Text size="lg" className="text-center">
            {`Total: ${Object.keys(vaultsWalletsBalancesSorted).length}`}
          </Text>
        </div>

        <Button
          variant="primary"
          size="sm"
          onClick={() => null}
          className="text-nowrap"
        >
          <Plus size={16} />
          Add Wallet
        </Button>
      </div>
      <div className="flex flex-col gap-3">
        {Object.entries(vaultsWalletsBalancesSorted).map(
          ([joinnAddress, wallet]) =>
            wallet && (
              <ExternalWallet
                key={wallet.walletAddress}
                accountBalance={wallet.totalVaultParticipation
                  .toFixed(2)
                  .toString()}
                chainWalletBalance={wallet.usdcBalance}
                isMasterWallet={joinnWalletAddress === joinnAddress}
                isWalletConnected={address === wallet.walletAddress}
                walletAddress={wallet.walletAddress}
                walletName={wallet.walletName} // Adjust to the actual name if available
              />
            ),
        )}
      </div>
    </Card>
  );
}

import { Spinner, Text } from "@/components";
import {
  Button,
  Card,
  Checkbox,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Separator,
} from "@/components/ui";
import { getHighlightedTexts } from "@/lib/utils";
import { useKyc } from "@/redux/kyc/kyc.slice";
import { useUpdateUserProfileMutation } from "@/redux/user/user.api";
import { KycProps } from "@/types/kyc";
import { zodResolver } from "@hookform/resolvers/zod";
import { isEmpty } from "lodash";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { z } from "zod";

const items = [
  {
    id: "joinn-terms",
    label: "Joinn",
    content: "I have read and accept the Terms of Service",
    highlights: [
      {
        label: "Terms of Service",
        link: "",
      },
    ],
  },
  {
    id: "joinn-privacy",
    label: "Joinn",
    content:
      "I understand and accept the Privacy Policy and I consent to the collection, storage, and processing of my personal information accordingly.",
    highlights: [
      {
        label: "Privacy Policy",
        link: "",
      },
    ],
  },
  {
    id: "swarm-terms",
    label: "Swarm",
    content: "I have read and accept the Terms of Service",
    highlights: [
      {
        label: "Terms of Service",
        link: "",
      },
    ],
  },
  {
    id: "swarm-privacy",
    label: "Swarm",
    content:
      "I understand and accept the Privacy Policy and I consent to the collection, storage, and processing of my personal information accordingly.",
    highlights: [
      {
        label: "Privacy Policy",
        link: "https://docs.swarm.com/about/terms/privacy",
      },
    ],
  },
];

const UserFormSchema = z.object({
  items: z.array(z.string()).refine((value) => value.length === items.length, {
    message: "You have to accept all terms.",
  }),
});

export function UserOnboarding(props: Readonly<KycProps>) {
  const { isStepCompleted, navButtons } = props;
  const { isOnboardingEnabled } = useKyc();

  const [updateUserProfile, result] = useUpdateUserProfileMutation();

  function getDefaultValues() {
    if (isStepCompleted) {
      return ["joinn-terms", "joinn-privacy", "swarm-terms", "swarm-privacy"];
    }

    return [];
  }

  const form = useForm<z.infer<typeof UserFormSchema>>({
    resolver: zodResolver(UserFormSchema),
    defaultValues: {
      items: getDefaultValues(),
    },
  });

  async function onSubmit(data: z.infer<typeof UserFormSchema>) {
    await updateUserProfile({
      bofTermsOfService: true,
      bofPrivacyPolicy: true,
      swarmTermsOfService: true,
      swarmPrivacyPolicy: true,
      immersveTermsOfUse: true,
      immersvePrivacyPolicy: true,
    });

    console.log(
      "You submitted the following values:: ",
      JSON.stringify(data, null, 2),
    );
  }

  return (
    <div className="col-auto grid max-w-screen-xl gap-6 lg:grid-cols-2">
      <div className="flex flex-col gap-5">
        {navButtons}
        <Text size="2xl" variant="secondary">
          Step 1: <span className="text-foreground"> User Onboarding</span>
        </Text>
        <Text size="sm">
          In order to interact with Joinn’s Earn Accounts and other products and
          services, Joinn require users to accept and agree to the documents
          listed in Step 1. Some of these documents are from third parties that
          Joinn has partnered with in order to be able to provide the unique
          products and services on offer.
        </Text>
        <Text size="sm">
          Once you have completed all 4 steps successfully your Joinn profile
          will be verified and any linked external wallets to your profile will
          also be verified to interact with Joinn’s Earn Accounts.
        </Text>
      </div>

      <Card variant={isStepCompleted ? "success" : "default"}>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <FormField
              control={form.control}
              name="items"
              render={() => (
                <FormItem>
                  {items.map((item, index) => {
                    const texts = getHighlightedTexts(
                      item.highlights,
                      item.content,
                    );

                    return (
                      <div key={item.id}>
                        {(index === 0 ||
                          item.label !== items[index - 1].label) && (
                          <div className="mb-4 flex items-center gap-4">
                            <FormLabel className="mt-2 px-0 text-xl text-primary max-sm:text-lg">
                              {item.label}
                            </FormLabel>
                            <Separator className="shrink" />
                          </div>
                        )}
                        <FormField
                          control={form.control}
                          name="items"
                          render={({ field }) => {
                            return (
                              <FormItem
                                key={item.id}
                                className="flex flex-row items-start space-y-0 py-2"
                              >
                                <FormControl>
                                  <Checkbox
                                    disabled={
                                      !isOnboardingEnabled || isStepCompleted
                                    }
                                    checked={field.value?.includes(item.id)}
                                    onCheckedChange={(checked) => {
                                      const values = [...field.value];

                                      return checked
                                        ? field.onChange([...values, item.id])
                                        : field.onChange(
                                            values.filter((value) => {
                                              return value !== item.id;
                                            }),
                                          );
                                    }}
                                  />
                                </FormControl>
                                <FormLabel className="h-full pl-3 pr-0 text-sm font-normal text-foreground/50 max-sm:text-xs">
                                  {texts.map((text, textIndex) => {
                                    const accent = item.highlights.find(
                                      (accent) => {
                                        return accent.label === text;
                                      },
                                    );

                                    return !isEmpty(accent) ? (
                                      <Link
                                        to={accent.link}
                                        target="_blank"
                                        className="font-semibold text-secondary underline"
                                        key={`${item.label}-${item.id}-${textIndex}`}
                                      >
                                        {text}
                                      </Link>
                                    ) : (
                                      <span
                                        key={`${item.label}-${item.id}-${textIndex}`}
                                      >
                                        {text}
                                      </span>
                                    );
                                  })}
                                </FormLabel>
                              </FormItem>
                            );
                          }}
                        />
                      </div>
                    );
                  })}
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex w-full justify-end">
              <Button
                disabled={!isOnboardingEnabled || isStepCompleted}
                variant={isStepCompleted ? "success" : "primary"}
                className="rounded"
                type="submit"
                size="sm"
              >
                {isStepCompleted
                  ? "I have accepted all terms and policies"
                  : "I accept and agree to the above"}

                {result.isLoading && <Spinner className="mx-0" />}
              </Button>
            </div>
          </form>
        </Form>
      </Card>
    </div>
  );
}

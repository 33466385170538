import { useNetworkConfig } from "@/hooks";
import { cn } from "@/lib/utils";
import { useAccount } from "wagmi";
import { Text } from "./typography";

type ChainLabelProps = {
  className?: string;
};

export function ChainLabel({ className }: Readonly<ChainLabelProps>) {
  const { chain } = useAccount();
  const { chainImage } = useNetworkConfig({ activeChain: chain?.id });

  if (!chain) return null;

  return (
    <div className={cn("items-starts flex justify-start gap-2", className)}>
      {chainImage && (
        <img
          src={chainImage}
          alt={`Icon of ${chain?.name} chain`}
          className="h-3 opacity-50"
        />
      )}
      <Text size="xs" variant="label" className="font-normal">
        {chain?.name}
      </Text>
    </div>
  );
}

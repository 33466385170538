import { ActivityPanel } from "@/components/activityPanel";
import { PortfolioOverview } from "@/components/portfolioOverview";
import { Card } from "@/components/ui";
import { Outlet } from "react-router-dom";
import EarnAccount from "./EarnAccount/EarnAccount";
import ExternalWallets from "./ExternalWallets/ExternalWallets";
import SpendingAccountSection from "./SpendingAccountSection/SpendingAccountSection";
import { VerifyAccount } from "./VerifyAccount";

export function Dashboard() {
  return (
    <div className="flex flex-wrap gap-4 py-4">
      <VerifyAccount />
      <Card
        variant="container"
        size="md"
        className="flex flex-[1_1_60%] flex-col gap-4 lg:col-span-2"
        radius="lg"
      >
        <SpendingAccountSection />
        <EarnAccount />
        <ExternalWallets />
      </Card>
      <Card
        variant="container"
        size="md"
        className="flex flex-[1_1_25%] flex-col gap-4 lg:col-span-1"
        radius="lg"
      >
        <PortfolioOverview />
        <ActivityPanel />
      </Card>
      <Outlet />
    </div>
  );
}

import { config } from "@/config";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl =
  config.BLOCKCHAIN_NET === "mainnet"
    ? "https://api.immersve.com/auth/"
    : "https://test.immersve.com/auth/";

export const getNetwork = () => {
  if (config.BLOCKCHAIN_NET === "mainnet") {
    return "polygon-mainnet";
  }
  return "polygon-amoy";
};

const dynamicBaseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers) => {
    const tokenData = sessionStorage.getItem("immersveTokenData");
    if (tokenData) {
      const { accessToken } = JSON.parse(tokenData);
      headers.set("Authorization", `Bearer ${accessToken}`);
    }
    return headers;
  },
});

export const immersveAuthApi = createApi({
  reducerPath: "immersveAuthApi",
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    initiateLogin: builder.mutation({
      query: (walletAddress: string) => ({
        url: "login-init",
        method: "POST",
        body: {
          loginMethod: "siwe",
          network: getNetwork(),
          clientApplicationId: config.IMMERSVE_CLIENT_APPLICATION_ID,
          scopes: ["cardholder-partner"],
          address: walletAddress,
          url: window.location.origin,
          autoSignup: true,
        },
      }),
    }),
    completeLogin: builder.mutation({
      query: ({
        loginRequestId,
        signature,
      }: {
        loginRequestId: string;
        signature: string;
      }) => ({
        url: "login-complete",
        method: "POST",
        body: {
          loginRequestId,
          signature,
        },
      }),
    }),
    logout: builder.mutation({
      query: () => {
        return {
          url: "logout",
          method: "POST",
        };
      },
    }),
    exchangeAuthToken: builder.mutation({
      query: ({
        refreshToken,
        clientApplicationId,
      }: {
        refreshToken: string;
        clientApplicationId: string;
      }) => ({
        url: "token",
        method: "POST",
        body: {
          refreshToken,
          clientApplicationId,
        },
      }),
    }),
  }),
});

export const {
  useInitiateLoginMutation,
  useCompleteLoginMutation,
  useLogoutMutation,
  useExchangeAuthTokenMutation,
} = immersveAuthApi;

// Export hooks for usage in functional components
export const { exchangeAuthToken } = immersveAuthApi.endpoints;

import { toast } from "@/components/hooks/use-toast";
import { config } from "@/config";
import { parseWeiToToken } from "@/const";
import { ImmersveStage } from "@/const/kycStatus";
import {
  useCreateFundingSourceMutation,
  useGetPrerequisitesQuery,
  useListFundingSourcesQuery,
} from "@/redux/immersve/immersve.api";
import { getNetwork } from "@/redux/immersve/immersve.authApi";
import { useImmersveState } from "@/redux/immersve/immersve.slice";
import {
  GetFundingChannelResp,
  SpendingAccountData,
} from "@/services/interfaces";
import { getImmersveTokenData, handleError } from "@/utils";
import { useCallback, useMemo } from "react";
import { useAccount } from "wagmi";
import { useDashboard } from ".";

export function useSpending() {
  const { cardholderAccountId } = getImmersveTokenData();
  const { address = "" } = useAccount();
  const { info, userWallets } = useDashboard();
  const { isSpendingStateHidden } = useImmersveState();
  const {
    data: fundingSources,
    isFetching,
    isLoading,
    refetch,
  } = useListFundingSourcesQuery(cardholderAccountId, {
    skip: !cardholderAccountId,
  });

  const { chainId } = useAccount();
  const [createFundingSourceMutation] = useCreateFundingSourceMutation();

  const joinnWalletAddress = userWallets?.joinnWalletAddress;

  const hasFundingSource: GetFundingChannelResp | false = useMemo(() => {
    const network = getNetwork();

    if (!fundingSources || !joinnWalletAddress) return false;

    return (
      fundingSources.items.find(
        (fs) =>
          fs.externalId === joinnWalletAddress &&
          fs.network === network &&
          fs.fundingChannelId === config.IMMERSVE_FUNDING_CHANNEL_ID,
      ) ?? false
    );
  }, [fundingSources, joinnWalletAddress]);

  const { data: spending } = useGetPrerequisitesQuery(
    {
      cardProgramId: config.IMMERSVE_CARD_PROGRAM_ID,
      fundingSourceId: hasFundingSource ? hasFundingSource.id : "",
      spendableAmount: "1", // TODO: To be confirmed
      kycType: "immersve-conducted",
    },
    // do not trigger spending-prerequisite when there is no fundingsource
    { skip: !hasFundingSource },
  );

  const spendingAccount: SpendingAccountData | undefined = useMemo(() => {
    if (!hasFundingSource || !info) return;

    return {
      address: info?.immersveAddress,
      name: "Spending Account",
      balance: hasFundingSource.balance
        ? Number(parseWeiToToken(hasFundingSource.balance, chainId))
        : undefined,
      decimals: 6,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFundingSource, chainId]);

  async function createFundingSource() {
    try {
      const { cardholderAccountId } = getImmersveTokenData();
      if (!cardholderAccountId) throw new Error("No cardholderAccountId found");
      if (!info) throw new Error("No info found");
      if (!userWallets?.joinnWalletAddress)
        throw new Error("No joinnWalletAddress found");

      const createFundingSourceResp = await createFundingSourceMutation({
        accountId: cardholderAccountId,
        fundingAddress: userWallets?.joinnWalletAddress,
        fundingChannelId: config.IMMERSVE_FUNDING_CHANNEL_ID,
      }).unwrap();

      await refetch();

      toast({
        variant: "success",
        title: "Success!",
        description: `Funding Source successfully created`,
      });

      return createFundingSourceResp;
    } catch (error) {
      console.error("Error creating funding source:", error);
      handleError(error);
    }
  }

  const kyc = useMemo(() => {
    return (
      spending?.prerequisites.filter((prereq) => {
        return prereq.stage === ImmersveStage.KYC;
      })[0] ?? undefined
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spending]);

  const getIsSpendingHidden = useCallback(() => {
    const spending = localStorage.getItem("hiddenAccounts");

    if (spending) {
      const data: string[] = JSON.parse(spending ?? "");
      const isAccountHidden = data.find((account) => {
        return account === address;
      });

      return isAccountHidden ?? isSpendingStateHidden;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, isSpendingStateHidden]);

  return {
    fundingSourceList: fundingSources,
    isFundingSourceFetching: isFetching || isLoading,
    hasFundingSource,
    createFundingSource,
    refetchFundingSource: refetch,
    spendingAccount,
    kyc,
    prerequisites: spending?.prerequisites,
    isSpendingHidden: getIsSpendingHidden(),
  };
}

import { Stepper } from "@/components";
import { Button, Card } from "@/components/ui";
import {
  Communication,
  KycSharingApproval,
  KycVerification,
  UserOnboarding,
} from "@/pages/KYC/Steps";
import { useUserProfileQuery } from "@/redux/user/user.api";
import { useEffect, useState } from "react";
import { SuccessPage } from "./Steps/Success";

export default function OnboardingProcess() {
  const { data: user, isSuccess, isFetching, refetch } = useUserProfileQuery();

  const [activeStep, setActiveStep] = useState(1);
  const [completedStep, setCompletedStep] = useState(0);

  const steps = [
    "User Onboarding",
    "Communication",
    "KYC Verification",
    "KYC Sharing Approval",
  ];

  const isOnboardingCompleted =
    !!user?.bofPrivacyPolicy &&
    !!user?.bofTermsOfService &&
    !!user?.swarmPrivacyPolicy &&
    !!user?.swarmTermsOfService &&
    !!user?.immersveTermsOfUse;
  // TODO: check why some user have immersvePrivacyPolicy as null
  // !!user?.immersvePrivacyPolicy;

  function setStep(step: number) {
    setCompletedStep(step);
    setActiveStep(step + 1);
  }

  function getCompletedStep() {
    if (user?.dataShareAgreement) {
      setStep(4);
    } else if (user?.kyc?.status === "APPROVED") {
      setStep(3);
    } else if (user?.emailVerification?.isVerified) {
      setStep(2);
    } else if (isOnboardingCompleted) {
      setStep(1);
    } else {
      setStep(0);
    }
  }

  function handleBack() {
    setActiveStep(activeStep - 1);
  }

  function handleNext() {
    setActiveStep(activeStep + 1);
  }

  function getNavButtons() {
    return (
      <div className="flex gap-3 pb-5 max-md:pb-3">
        <Button
          disabled={activeStep <= 1}
          variant="outline"
          size="md"
          onClick={() => {
            handleBack();
          }}
          className="rounded"
        >
          Back
        </Button>
        <Button
          disabled={activeStep - 1 >= completedStep}
          variant="primary"
          size="md"
          className="rounded"
          onClick={() => {
            handleNext();
          }}
        >
          Next
        </Button>
      </div>
    );
  }

  useEffect(() => {
    if (isSuccess) {
      getCompletedStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isFetching]);

  return (
    <Card
      radius="lg"
      className="relative z-10 flex h-[-webkit-fill-available] min-h-[calc(100vh_-_340px)] flex-col items-center gap-20 py-14 max-md:items-start max-md:gap-2"
    >
      <Stepper
        steps={steps}
        activeStep={activeStep}
        completedStep={completedStep}
        onSelectStep={setActiveStep}
      />

      {activeStep === 1 && (
        <UserOnboarding
          navButtons={getNavButtons()}
          isStepCompleted={isOnboardingCompleted}
        />
      )}

      {activeStep === 2 && (
        <Communication
          navButtons={getNavButtons()}
          isStepCompleted={user?.emailVerification?.isVerified || false}
          verifiedEmail={user?.emailVerification?.email ?? ""}
          refetch={refetch}
        />
      )}

      {activeStep === 3 && (
        <KycVerification
          navButtons={getNavButtons()}
          isStepCompleted={user?.kyc?.status === "APPROVED"}
          user={user?.kyc || null}
        />
      )}

      {activeStep === 4 && (
        <KycSharingApproval
          navButtons={getNavButtons()}
          isStepCompleted={user?.dataShareAgreement || false}
        />
      )}

      {activeStep === 5 && <SuccessPage />}
    </Card>
  );
}

import { Text } from "@/components";
import {
  TransferCard,
  TransferInput,
  TransferProgress,
} from "@/components/transaction";
import { ActionButtons } from "@/components/transaction/transactionActionButtons";
import { TransferDetails } from "@/components/transaction/transferDetails";
import { TransferDropdown } from "@/components/transaction/transferDropdown";
import { Button, Card } from "@/components/ui";
import { Transactions } from "@/const";
import { useDashboard } from "@/hooks";
import { useTransactions } from "@/hooks/useTransactions";
import { cn } from "@/lib/utils";
import { useModalState } from "@/redux/modal/modal.slice";
import { X } from "lucide-react";
import { useEffect, useState } from "react";

type WithdrawProps = {
  fromAddress?: string;
  type?: "modal" | "panel";
};

export function Withdraw({
  fromAddress,
  type = "modal",
}: Readonly<WithdrawProps>) {
  const { state, actions } = useTransactions(
    Transactions.WITHDRAW,
    fromAddress,
  );
  const { closeModal } = useModalState();
  const [detailsData] = useState<any[]>([
    {
      item: "Available Reserves",
      description: "these are available reserves",
      value: 1000000,
    },
    {
      item: "Share Price",
      description: "these are total shares",
      value: 2.5,
    },
    {
      item: "Shares",
      description: "these are total shares",
      value: 44000,
    },
    {
      item: "Share Value",
      description: "these are total shares",
      value: 1100000,
    },
  ]);

  const { refetch } = useDashboard();

  useEffect(() => {
    refetch();
  }, []);

  return (
    <Card
      className={cn(
        "p-5",
        type === "panel" && "border-none bg-transparent p-0 shadow-none",
      )}
    >
      <Button
        className="absolute right-6 top-6"
        disabled={state.isProcessing}
        onClick={() => {
          closeModal();
        }}
      >
        <X />
      </Button>
      <TransferDropdown
        data={state.fromRestData}
        label="From:"
        setDataItem={(e) => actions.setFrom(e)}
        selectedDataItem={state.from}
        className="mb-2"
      />
      <TransferDetails data={detailsData} className="mb-2" />
      <TransferDropdown
        data={state.toRestData}
        label="To:"
        setDataItem={(e) => actions.setTo(e)}
        selectedDataItem={state.to}
        className="mb-2"
      />
      <TransferInput
        value={state.amount}
        onChange={(e) => actions.setAmount(Number(e))}
        maxValue={state.from?.balance}
      />
      {state.isError && (
        <Text size="xs" variant="error" className="my-2 max-w-sm break-words">
          {state.isError}
        </Text>
      )}
      <TransferCard caption="Estimated Shares" value={state.from?.balance} />
      <TransferProgress
        status={state.status}
        isProcessing={state.isProcessing}
        transactionDescription="Withdraw"
      />
      <ActionButtons
        transactionLabel="Withdraw"
        isProcessing={state.isProcessing}
        isError={!!state.isError}
        status={state.status}
        type={type}
        onClose={() => {
          actions.setAmount(0);
          closeModal();
        }}
        onTransact={actions.onWithdraw}
      />
    </Card>
  );
}

import { InputField, Spinner, Text } from "@/components";
import {
  Button,
  Card,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Separator,
} from "@/components/ui";
import { cn } from "@/lib/utils";
import {
  useLazyVerifyCodeQuery,
  useVerifyEmailMutation,
} from "@/redux/email/email.api";
import { KycProps } from "@/types/kyc";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const EmailFormSchema = z.object({
  email: z.string().email({
    message: "Invalid email",
  }),
});

const EmailVerifySchema = z.object({
  code: z.string({
    message: "You have to submit the code",
  }),
});

type CommunicationProps = KycProps & {
  verifiedEmail: string;
  refetch: () => void;
};

export function Communication(props: CommunicationProps) {
  const { navButtons, verifiedEmail, isStepCompleted, refetch } = props;

  const maxRetry = 3;
  const maxDelay = 5;

  const [attempts, setAttempts] = useState<number>(maxRetry);
  const [delayInterval, setDelayInterval] = useState<number>(maxDelay);

  const [verifyEmail, emailResult] = useVerifyEmailMutation();
  const [verifyCode, codeResult] = useLazyVerifyCodeQuery();

  const emailForm = useForm<z.infer<typeof EmailFormSchema>>({
    resolver: zodResolver(EmailFormSchema),
    defaultValues: {
      email: verifiedEmail,
    },
  });

  const verifyForm = useForm<z.infer<typeof EmailVerifySchema>>({
    resolver: zodResolver(EmailVerifySchema),
    defaultValues: {
      code: "",
    },
  });

  function onSubmitEmail(data: z.infer<typeof EmailFormSchema>) {
    if (attempts > 0) {
      verifyEmail(data.email);
      setAttempts(attempts - 1);
    }
  }

  function getBtnLabel() {
    let label = "Verify";

    if (emailResult.isSuccess || attempts < maxRetry - 1) {
      label = "Resend";
    } else if (isStepCompleted) {
      label = "Verified";
    }

    return label;
  }

  async function onSubmitCode(data: z.infer<typeof EmailVerifySchema>) {
    await verifyCode(data.code);
    // refetch userUserProfile hook after verifying the email
    refetch();
  }

  useEffect(() => {
    if (delayInterval === 0) {
      setAttempts(maxRetry);
    }
  }, [delayInterval]);

  useEffect(() => {
    if (attempts === 0) {
      const timer = setInterval(() => {
        setDelayInterval((prev) => {
          return prev === 0 ? maxDelay : prev - 1;
        });
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [attempts]);

  return (
    <div className="col-auto grid max-w-screen-xl gap-6 lg:grid-cols-2">
      <div className="flex flex-col gap-5">
        {navButtons}
        <Text size="2xl" variant="secondary">
          Step 2: <span className="text-foreground"> Communication</span>
        </Text>
        <Text size="sm">
          Joinn requires users to provide a valid email address in case we need
          to contact you with important information about your account, the
          Joinn platform and/or Joinn products and services.
        </Text>
        <Text size="sm">
          To verify your email we will send you a code from hello@joinn.xyz to
          the email address you submit. Please ensure to check your spam and
          junk folders if you can not find the email in your inbox.
        </Text>
        <Text size="sm">
          Joinn's Privacy Policy which governs the collection and use of
          personal information can be viewed here.
        </Text>
      </div>
      <Card
        variant={isStepCompleted ? "success" : "default"}
        className={cn("flex flex-col justify-between gap-5")}
      >
        <Form {...emailForm}>
          <form
            onSubmit={emailForm.handleSubmit(onSubmitEmail)}
            className="flex flex-col justify-between gap-5 pb-5"
          >
            <div className="flex flex-col gap-4">
              <Text size="sm" variant="label">
                <span className="font-semibold">
                  I consent to receiving important updates and information
                </span>{" "}
                about my account and Joinn's platform by email.
              </Text>
              <Separator />
              <FormField
                control={emailForm.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <InputField
                        label="Email Address:"
                        value={field.value}
                        placeholder="Enter email address here..."
                        disabled={isStepCompleted}
                        onChange={(event) => {
                          const value = event.target.value;
                          field.onChange(value);
                        }}
                        endAdornment={
                          <Button
                            variant={isStepCompleted ? "success" : "primary"}
                            size="sm"
                            type="submit"
                            className="m-2 gap-1 rounded"
                            disabled={
                              field.value === "" ||
                              isStepCompleted ||
                              emailResult.isLoading ||
                              attempts === 0
                            }
                          >
                            {getBtnLabel()}
                            {emailResult.isLoading && (
                              <Spinner className="mx-0" />
                            )}
                          </Button>
                        }
                      />
                    </FormControl>
                    <FormMessage />
                    {emailResult.isSuccess && (
                      <Card size="sm" variant="success">
                        <Text size="sm">
                          Code has been sent,{" "}
                          <span className="text-secondary">
                            please check your email.
                          </span>
                        </Text>
                      </Card>
                    )}

                    {attempts === 0 && (
                      <Card size="sm">
                        <Text size="sm" variant="label">
                          Too many emails sent. Please wait for{" "}
                          <span className="text-secondary">{`${delayInterval}s`}</span>{" "}
                          before sending a new request.
                        </Text>
                      </Card>
                    )}
                  </FormItem>
                )}
              />
            </div>
          </form>
        </Form>
        {emailResult.isSuccess && (
          <Form {...verifyForm}>
            <form
              onSubmit={verifyForm.handleSubmit(onSubmitCode)}
              className="flex flex-col justify-between gap-5"
            >
              <div className="flex flex-col gap-4">
                <FormField
                  control={verifyForm.control}
                  name="code"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <InputField
                          label="Code:"
                          placeholder="Enter code sent via your email..."
                          onChange={(event) => {
                            const value = event.target.value;
                            field.onChange(value);
                          }}
                          endAdornment={
                            <Button
                              variant="primary"
                              size="sm"
                              type="submit"
                              className="m-2 gap-1 rounded"
                              disabled={
                                field.value === "" || codeResult.isLoading
                              }
                            >
                              Confirm
                              {codeResult.isLoading && (
                                <Spinner className="mx-0" />
                              )}
                            </Button>
                          }
                        />
                      </FormControl>
                      <FormMessage />
                      <Card size="sm">
                        <Text size="sm" variant="label">
                          By confirming, I accept and agree to the above.
                        </Text>
                      </Card>
                    </FormItem>
                  )}
                />
              </div>
            </form>
          </Form>
        )}
        {isStepCompleted && (
          <div className="flex justify-end">
            <Button
              variant="success"
              size="md"
              className="w-1/2 rounded"
              disabled
            >
              Completed
            </Button>
          </div>
        )}
      </Card>
    </div>
  );
}

import { ImmersveStageStatus } from "@/const/kycStatus";
import { useDashboard, useImmersve, useMastercard, useSpending } from "@/hooks";
import { useModalState } from "@/redux/modal/modal.slice";
import { Routes } from "@/routes/routers";
import { isUndefined } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import Mastercard from "../Mastercard/Mastercard";
import { MastercardActivation } from "../Mastercard/MastercardActivation";
import { PreKycSpending } from "../SpendingAccount/PreKycSpending";
import SpendingAccount from "../SpendingAccount/SpendingAccount";

export default function SpendingAccountSection() {
  const { action, state } = useImmersve();
  const { kyc, isSpendingHidden } = useSpending();
  const { info } = useDashboard();
  const { toggleModal } = useModalState();
  const {
    state: { hasMastercard },
  } = useMastercard();

  const location = useLocation();
  const [isConnecting, setIsConnecting] = useState(true);
  const [isLoginError, setIsLoginError] = useState(false);

  const isLoggingIn = isConnecting || (isUndefined(kyc) && !isLoginError);

  // This is on initial load only - toggle modal when redirected from Immersve KYC Flow
  useEffect(() => {
    if (location.pathname === Routes.MASTERCARD_ACTIVATION) {
      toggleModal({
        id: "pre-kyc-modal",
        isFullWidth: true,
        redirectBack: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function handleLogin() {
      if (info && !isSpendingHidden && state.isChainImmersveAllowed) {
        setIsConnecting(true);
        const response = await action.login();
        setIsLoginError(response?.isError ?? false);
        setIsConnecting(false);
      }
    }

    handleLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  const content = useMemo(() => {
    if (hasMastercard) {
      return (
        <>
          <SpendingAccount />
          <Mastercard />
        </>
      );
    } else if (kyc?.status === ImmersveStageStatus.OK) {
      return (
        <>
          <SpendingAccount />
          <MastercardActivation />
        </>
      );
    } else {
      return <PreKycSpending isConnecting={isLoggingIn} />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMastercard, kyc, isLoggingIn]);

  if (!state.isChainImmersveAllowed || isSpendingHidden) return false;

  return (
    <div className="flex flex-col gap-4 min-[920px]:max-[1020px]:flex-row lg:xl:flex-row">
      {content}
    </div>
  );
}

import { Text } from "@/components/";
import { Card, Separator } from "@/components/ui";
import { numberWithCommas } from "@/lib/utils";
import { isNil } from "lodash";

export type HoldingsProps = {
  sharesHeld?: number;
  sharesOfVault?: string;
  sharePrice?: number;
  sharesValue?: number;
  weeklyEarned?: number;
  totalEarned?: number;
  balance?: number;
  dailyLoad?: number;
  yearlyLoad?: number;
};

export type HoldingsFieldProps = {
  value?: number | string;
  label: string;
  divider?: boolean;
};

function HoldingsField(props: Readonly<HoldingsFieldProps>) {
  const { value, label, divider = true } = props;

  return (
    <div className="flex flex-col gap-2">
      <div className="flex-row-reverse items-center justify-between max-sm:flex">
        <Text size="lg" className="max-sm:text-end max-sm:text-sm">
          {value}
        </Text>
        <Text size="sm" variant="accent">
          {label}
        </Text>
      </div>
      {divider && <Separator className="hidden max-sm:flex" />}
    </div>
  );
}

export function HoldingsCard(props: Readonly<HoldingsProps>) {
  const {
    balance,
    dailyLoad,
    yearlyLoad,
    sharePrice,
    sharesOfVault,
    weeklyEarned,
    totalEarned,
  } = props;

  function getSharesHeld() {
    if (balance && sharePrice) {
      return Number(balance) / Number(sharePrice);
    }

    return 0;
  }

  return (
    <Card className="rounded-lg p-0 max-sm:p-0">
      <Text size="2xl" variant="primary" className="px-8 py-6 max-sm:p-4">
        Holdings
      </Text>
      <Separator />
      <div className="flex justify-between gap-5 px-8 py-6 max-sm:flex-col max-sm:gap-2 max-sm:p-4">
        <div className="flex flex-col gap-5 max-sm:gap-2">
          {!isNil(balance) && (
            <HoldingsField
              value={`${balance.toFixed(2)} USDC`}
              label="Balance"
            />
          )}
          {!isNil(sharesOfVault) && (
            <HoldingsField value={sharesOfVault} label="Share of Vault" />
          )}
        </div>

        <div className="flex flex-col gap-5 max-sm:gap-2">
          {!isNil(dailyLoad) && (
            <HoldingsField
              value={`${numberWithCommas(dailyLoad ?? "")} USDC`}
              label="Daily Load Limit Remaining"
            />
          )}
          {!isNil(sharePrice) && (
            <HoldingsField
              value={`${sharePrice.toFixed(2)} USDC`}
              label="Share Price"
            />
          )}
          {!isNil(weeklyEarned) && (
            <HoldingsField
              value={`${weeklyEarned.toFixed(2)} USDC`}
              label="Weekly Earned"
            />
          )}
        </div>

        <div className="flex flex-col gap-5 max-sm:gap-2">
          {!isNil(yearlyLoad) && (
            <HoldingsField
              value={`${numberWithCommas(yearlyLoad ?? "")} USDC`}
              label="Yearly Load Limit Remaining"
              divider={false}
            />
          )}
          {!isNil(sharePrice) && !isNil(balance) && (
            <HoldingsField
              value={getSharesHeld().toFixed(2)}
              label="Shares Held"
            />
          )}
          {!isNil(totalEarned) && (
            <HoldingsField
              value={`${totalEarned.toFixed(2)} USDC`}
              label="Total Earned"
              divider={false}
            />
          )}
        </div>
      </div>
    </Card>
  );
}

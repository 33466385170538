import Pencil from "@/assets/icons/pencil.svg";
import Star from "@/assets/icons/star.svg";
import { useLogout } from "@/hooks";
import { getMaskedAddress } from "@/lib/utils";
import { useModalState } from "@/redux/modal/modal.slice";
import { EllipsisVertical } from "lucide-react";
import { Dropdown } from "../";
import { CopyButton } from "../copyButton";
import { JoinnTooltip } from "../joinnTooltip";
import { Text } from "../typography";
import { Button, Card, Separator } from "../ui";

type ExternalWalletProps = {
  accountBalance: string;
  chainWalletBalance: string;
  isMasterWallet: boolean;
  isWalletConnected: boolean;
  walletAddress: string;
  walletName: string;
};

export function ExternalWallet({
  accountBalance,
  chainWalletBalance,
  isMasterWallet,
  isWalletConnected,
  walletAddress,
  walletName,
}: ExternalWalletProps) {
  const { disconnect } = useLogout();
  const { toggleModal } = useModalState();

  function handleDisconnectWallet() {
    alert("disconnect");
  }

  async function handleLogout() {
    await disconnect();
  }

  return (
    <>
      <Card variant="secondary" className="py-4">
        <div className="flex flex-col items-center justify-between gap-2 sm:flex-row sm:gap-4">
          <div className="w-full sm:w-1/3">
            <div className="flex w-full items-center justify-between gap-5">
              {isWalletConnected && (
                <div className="min-h-3 min-w-3 rounded-full bg-[hsl(101,100%,50%,100%)]" />
              )}
              <div className="flex grow items-center justify-between">
                <Text size="lg" className="font-semibold">
                  {walletName ? walletName : "Wallet Name"}
                </Text>
                <Button
                  variant="ghost"
                  onClick={() =>
                    toggleModal({
                      title: "Change Wallet Name",
                      id: "change-wallet-name",
                      data: { walletAddress, walletName },
                    })
                  }
                  className="min-h-4 min-w-4"
                >
                  <img src={Pencil} alt="Pencil Icon" className="opacity-50" />
                </Button>
              </div>
            </div>
            <div className="flex grow items-center justify-end gap-5 max-[470px]:justify-between">
              {isMasterWallet && <img src={Star} alt="Star Icon" />}
              <div className="flex grow items-center justify-between">
                <Text size="md" className="font-normal">
                  {getMaskedAddress(walletAddress, 4)}
                </Text>
                <CopyButton text={walletAddress} className="opacity-50" />
              </div>
            </div>
          </div>
          <Separator orientation="vertical" className="hidden h-8 sm:block" />
          <div className="w-full sm:w-1/3">
            <Text size="lg" className="font-normal">
              {accountBalance} USDC
            </Text>
            <JoinnTooltip description="Combined balance of assets in all Earn Accounts.">
              <Text variant="accent">Portfolio Value</Text>
            </JoinnTooltip>
          </div>
          <Separator orientation="vertical" className="hidden h-8 sm:block" />
          <div className="flex w-full items-center justify-between sm:w-1/3">
            <div>
              <Text size="lg" className="font-normal">
                {chainWalletBalance} USDC
              </Text>
              <JoinnTooltip description="Available USDC wallet balance on connected network.">
                <Text variant="accent"> Available USDC</Text>
              </JoinnTooltip>
            </div>
            <Dropdown
              triggerProps={{ arrow: false }}
              className="min-w-48"
              options={[
                {
                  id: "1",
                  label: isMasterWallet ? "Logout" : "Disconnect wallet",
                },
              ]}
              label={<EllipsisVertical />}
              onSelectOption={() => {
                if (isMasterWallet) handleLogout();
                else handleDisconnectWallet();
              }}
            />
          </div>
        </div>
      </Card>
    </>
  );
}

import { config } from "@/config";
import {
  CreateCardArgs,
  CreateCardResp,
  CreateChallengeResp,
  CreateFundingSourceArgs,
  CreateFundingSourceResp,
  GetFundingChannelResp,
  ListCardsResp,
  ListFundingSourcesResp,
  PrerequisitesRequest,
  PrerequisitesResponse,
  SupportedRegionsResponse,
} from "@/services/interfaces/immersve";
import { getImmersveTokenData } from "@/utils";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { exchangeAuthToken, getNetwork } from "./immersve.authApi";

const baseUrl =
  config.BLOCKCHAIN_NET === "mainnet"
    ? "https://api.immersve.com/"
    : "https://test.immersve.com/";

const dynamicBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const rawBaseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const { accessToken } = getImmersveTokenData();
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
  });

  const result = await rawBaseQuery(args, api, extraOptions);

  // Check for 401 Unauthorized response
  if (result.error && result.error.status === 401) {
    const dispatch = api.dispatch; // Access dispatch from the api

    // Assuming you have a refreshToken available
    const { refreshToken, cardholderAccountId } = getImmersveTokenData();

    // Dispatch the exchangeAuthToken mutation
    const { data, error } = await dispatch(
      exchangeAuthToken.initiate({
        refreshToken,
        clientApplicationId: config.IMMERSVE_CLIENT_APPLICATION_ID,
      }),
    );

    if (error) {
      console.error("Failed to exchange token:", error);
      sessionStorage.removeItem("immersveTokenData"); // Clear token data
      return result;
    }

    // Merge new token data with existing session storage data tok keep cardholderAccountId and id
    const newTokenData = {
      ...data,
      cardholderAccountId,
    };

    sessionStorage.setItem("immersveTokenData", JSON.stringify(newTokenData));

    return await rawBaseQuery(args, api, extraOptions);
  }

  return result;
};

const getFundingTypeName = () => {
  if (config.BLOCKCHAIN_NET === "mainnet") {
    return "polygon-usdc-universal-evm-live";
  }
  return "polygon-amoy-usdc-universal-evm-test";
};

export const immersveApi = createApi({
  reducerPath: "immersveApi",
  baseQuery: dynamicBaseQuery,
  tagTypes: ["Funding"],
  endpoints: (builder) => ({
    getFundingChannel: builder.query({
      query: (fundingChannelId) => `funding-channels/${fundingChannelId}`,
    }),
    createFundingChannel: builder.mutation({
      query: ({ accountId }) => ({
        url: `api/funding-channels`,
        method: "POST",
        body: {
          accountId,
          fundingTypeName: getFundingTypeName(),
          purpose: "card-funding",
        },
      }),
    }),
    listFundingSources: builder.query<ListFundingSourcesResp, string>({
      query: (accountId: string) => `api/accounts/${accountId}/funding-sources`,
    }),
    getFundingSource: builder.query<GetFundingChannelResp, string>({
      query: (fundingSourceId: string) =>
        `api/funding-source/${fundingSourceId}`,
    }),
    createFundingSource: builder.mutation<
      CreateFundingSourceResp,
      CreateFundingSourceArgs
    >({
      invalidatesTags: ["Funding"],
      query: ({
        accountId,
        fundingAddress,
        fundingChannelId,
      }: CreateFundingSourceArgs) => ({
        url: `api/funding-sources`,
        method: "POST",
        body: {
          accountId,
          fundingAddress,
          fundingChannelId,
        },
      }),
    }),
    createChallenge: builder.mutation<CreateChallengeResp, string>({
      query: (address: string) => ({
        url: "api/challenges",
        method: "POST",
        body: {
          address,
          purpose: "claim-web3-address",
          network: getNetwork(),
        },
      }),
    }),
    listCards: builder.query<ListCardsResp, string>({
      query: (accountId: string) => ({
        url: `api/accounts/${accountId}/cards`,
        method: "GET",
      }),
    }),
    createCard: builder.mutation<CreateCardResp, CreateCardArgs>({
      query: ({ cardProgramId, fundingSourceId }: CreateCardArgs) => ({
        url: `api/cards`,
        method: "POST",
        body: {
          cardProgramId,
          fundingSourceId,
        },
      }),
    }),
    getPrerequisites: builder.query<
      PrerequisitesResponse,
      PrerequisitesRequest
    >({
      providesTags: ["Funding"],
      query: ({ ...body }) => ({
        url: `api/spending-prerequisites`,
        method: "POST",
        body: {
          ...body,
          // Only supported currency
          spendableCurrency: "USD",
          kycRedirectUrl: config.IMMERSVE_REDIRECT_URL,
        },
      }),
    }),
    getSupportedRegions: builder.query<SupportedRegionsResponse, string>({
      query: (partnerAccountId) => ({
        url: `api/accounts/${partnerAccountId}/supported-regions`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetFundingChannelQuery,
  useCreateFundingChannelMutation,
  useListFundingSourcesQuery,
  useGetFundingSourceQuery,
  useCreateFundingSourceMutation,
  useLazyListFundingSourcesQuery,
  useLazyGetFundingChannelQuery,
  useCreateChallengeMutation,
  useListCardsQuery,
  useLazyListCardsQuery,
  useCreateCardMutation,
  useGetPrerequisitesQuery,
  useGetSupportedRegionsQuery,
} = immersveApi;

import MastercardIcon from "@/assets/images/mc-logo.svg";
import { ChainLabel, Text } from "@/components";
import { ButtonsSet } from "@/components/spendingAccount";
import { Card, Separator, Skeleton } from "@/components/ui";
import { useBalances, useDashboard } from "@/hooks";
import { Routes } from "@/routes/routers";
import { NavLink } from "react-router-dom";

export default function SpendingAccount() {
  const { spendingAccountBalance, balanceIsProcessing } = useBalances();
  const { info } = useDashboard();

  const displayBalance = () => {
    if (!spendingAccountBalance) return;
    return (
      <div>
        {balanceIsProcessing ? (
          <div className="flex items-center gap-2">
            <Skeleton className="h-6 w-20" />
            <Text size="2xl" className="text-right font-normal text-accent">
              USDC
            </Text>
          </div>
        ) : (
          <Text size="2xl" className="text-right font-normal">
            {spendingAccountBalance.toFixed(2)}
            <span className="font-normal text-accent"> USDC</span>
          </Text>
        )}
        <Separator />
        <Text variant="label" className="text-right">
          Balance
        </Text>
      </div>
    );
  };

  return (
    <Card radius="md" size="md">
      <div className="flex justify-between">
        <div>
          <h2 className="text-2xl font-bold">Spending Account</h2>
          <NavLink
            to={Routes.ACCOUNT_INFORMATION.replace(
              ":account",
              info?.immersveAddress ?? "",
            )}
          >
            Account Information
          </NavLink>
          {/* </Button> */}
          <div className="mt-3 flex items-center gap-2">
            <img src={MastercardIcon} alt="Mastercard" />
            <Text className="w-1 text-wrap font-medium" variant="label">
              Mastercard Linked
            </Text>
          </div>
        </div>
        <div className="flex flex-col justify-between">
          <ChainLabel className="self-end" />
          {displayBalance()}
        </div>
      </div>
      <div className="mt-9 flex flex-wrap items-center gap-5">
        <ButtonsSet />
      </div>
    </Card>
  );
}

import { Text } from "../typography";
import { Card } from "../ui";

type TransferCardProps = {
  caption: string | number;
  value?: number;
};

export function TransferCard({ caption, value }: Readonly<TransferCardProps>) {
  return (
    <Card
      className="mt-2 flex h-9 items-center justify-between rounded px-2 py-0"
      radius="default"
    >
      <Text size="xs" variant="label">
        {caption}
      </Text>
      <Text size="xs" className="text-secondary">
        {value ? `${value} USDC` : "n/a"}
      </Text>
    </Card>
  );
}

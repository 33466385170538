import { Spinner, Text } from "@/components";
import { toast } from "@/components/hooks/use-toast";
import { Button, Separator } from "@/components/ui";
import { ImmersveStageStatus } from "@/const";
import { useDashboard, useImmersve, useMastercard, useSpending } from "@/hooks";
import { getKycBtnVariant } from "@/lib/utils";
import { useImmersveState } from "@/redux/immersve/immersve.slice";
import { useModalState } from "@/redux/modal/modal.slice";
import { Routes } from "@/routes/routers";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

export function ActivationModal() {
  const {
    action: { login },
  } = useImmersve();
  const { kyc, hasFundingSource, createFundingSource } = useSpending();
  const { isUserProfileLoading } = useDashboard();

  const { closeModal } = useModalState();
  const { isConnected, isConnecting } = useImmersveState();

  const {
    state: { hasMastercard },
    action: { createMastercard },
  } = useMastercard();

  const isKycCompleted = kyc?.status === ImmersveStageStatus.OK;
  const isKycPending = kyc?.status === ImmersveStageStatus.PENDING;
  const isKycError = kyc?.status === ImmersveStageStatus.BLOCKED;

  useEffect(() => {
    // do not remove this for now
    console.log("===================");
    console.log("KYC STATE:: ", kyc);
    console.log("===================");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kyc?.status]);

  const navigate = useNavigate();

  const [isCreatingMastercard, setIsCreatingMastercard] = useState(false);

  async function handleCreateMastercard() {
    setIsCreatingMastercard(true);
    await createMastercard();
    setIsCreatingMastercard(false);
  }

  async function handleSignIn() {
    if (isConnected) {
      await createFundingSource();
    } else {
      const { isSuccess } = await login();
      console.log("hasFundingSource:: ", hasFundingSource);
      if (isSuccess) {
        await createFundingSource();
      } else {
        toast({
          variant: "error",
          description: "Unable to sign you in, please try again.",
        });
      }
    }
  }

  const verificationLabel = useMemo(() => {
    let label = "Start Activation Process";

    if (isKycCompleted) {
      label = "Account Verified!";
    } else if (isKycPending) {
      label = "Verification is being reviewed";
    } else if (isKycError) {
      label = "Verification failed, try again!";
    }

    return label;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kyc?.status]);

  const signLabel = useMemo(() => {
    let label = "Connect to Immersve";

    if (isUserProfileLoading || isConnecting) {
      label = "Connecting to Immersve";
    } else if (isConnected && !hasFundingSource) {
      label = "Proceed";
    } else if (hasFundingSource) {
      label = "Connected to Immersve";
    }

    return label;
  }, [isConnected, hasFundingSource, isUserProfileLoading, isConnecting]);

  return (
    <div className="flex w-full max-w-screen-xl flex-wrap gap-5 max-md:flex-col max-md:p-5">
      <div className="flex flex-1 flex-col justify-between gap-5">
        <div className="flex flex-col gap-3">
          <Text
            variant="accent"
            size="2xl"
            className="w-fit rounded-full bg-foreground px-7 py-4 font-bold"
          >
            1
          </Text>
          <Text size="xl" className="font-bold">
            Sign a Message
          </Text>
          {!isConnected && (
            <Text size="sm" className="text-foreground/75">
              This step requires you to{" "}
              <span className="font-bold text-foreground">
                sign a message with our Mastercard® partner Immersve
              </span>{" "}
              in order to initiate the process.
            </Text>
          )}

          {isConnected && !hasFundingSource && (
            <Text size="sm" className="text-foreground/75">
              You are already signed in{" "}
              <span className="font-bold text-foreground">
                with our Mastercard® partner Immersve
              </span>{" "}
              , please click proceed in order to revalidate this step.
            </Text>
          )}

          {hasFundingSource && (
            <Text size="sm" className="text-foreground/75">
              You are already signed in{" "}
              <span className="font-bold text-foreground">
                with our Mastercard® partner Immersve,
              </span>{" "}
              please proceed to the next step.
            </Text>
          )}
        </div>
        <Button
          disabled={!isEmpty(hasFundingSource)}
          readOnly={isConnecting}
          variant={hasFundingSource ? "success" : "primary"}
          size="md"
          className="w-fit self-end"
          onClick={handleSignIn}
        >
          {signLabel}
          {(isConnecting || isUserProfileLoading) && <Spinner />}
        </Button>
      </div>
      <Separator orientation="vertical" className="max-md:hidden" />
      <Separator className="md:hidden" />
      <div className="flex flex-1 flex-col justify-between gap-5">
        <div className="flex flex-col gap-3">
          <Text
            variant="accent"
            size="2xl"
            className="w-fit rounded-full bg-foreground px-7 py-4 font-bold"
          >
            2
          </Text>
          <Text size="xl" className="font-bold">
            Quick Verification
          </Text>
          <Text size="sm" className="text-foreground/75">
            Verify your identity by going through an easy and quick
            verification. This step will require you to{" "}
            <span className="font-bold text-foreground">pay a minimal fee</span>
            , charged through your wallet address.{" "}
          </Text>
        </div>
        <Button
          variant={getKycBtnVariant(kyc?.status ?? "")}
          size="md"
          className="w-fit self-end"
          disabled={!hasFundingSource}
          onClick={() => {
            navigate(Routes.IMMERSVE_KYC);
            closeModal();
          }}
        >
          {verificationLabel}
        </Button>
      </div>
      <Separator orientation="vertical" className="max-md:hidden" />
      <Separator className="md:hidden" />
      <div className="flex flex-1 flex-col justify-between gap-5">
        <div className="flex flex-col gap-3">
          <Text
            variant="accent"
            size="2xl"
            className="w-fit rounded-full bg-foreground px-7 py-4 font-bold"
          >
            3
          </Text>
          <Text size="xl" className="font-bold">
            Mastercard Creation
          </Text>
          <Text size="sm" className="text-foreground/75">
            Hooray! You are on the final step! Create a Mastercard to fully
            enjoy the services offered.
          </Text>
        </div>
        <Button
          variant={hasMastercard ? "success" : "primary"}
          size="md"
          className="w-fit self-end"
          disabled={!isKycCompleted}
          onClick={handleCreateMastercard}
        >
          {hasMastercard ? "Mastercard Created!" : "Create a Mastercard"}
          {isCreatingMastercard && <Spinner />}
        </Button>
      </div>
    </div>
  );
}
